import { api } from 'redux/api';
import { Response } from 'redux/types';

import { Coupon, Product, Subscription } from './types';

export const billingApi = api.injectEndpoints({
  endpoints: builder => ({
    getProducts: builder.query<Product[], void>({
      query: () => ({
        url: '/api/v1/billing/products',
        method: 'GET',
      }),
      transformResponse: (response: Response<Product[]>) => response.content,
    }),

    getCoupons: builder.query<Coupon[], void>({
      query: () => ({
        url: '/api/v1/billing/coupons',
        method: 'GET',
      }),
      transformResponse: (response: Response<Coupon[]>) => response.content,
    }),

    getSubscription: builder.query<Subscription, string>({
      query: organization_id => ({
        url: '/api/v1/billing/subscription',
        method: 'GET',
        params: { organization_id },
      }),
      providesTags: ['subscription'],
      transformResponse: (response: Response<Subscription>) => response.content,
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetSubscriptionQuery,
  useGetCouponsQuery,
} = billingApi;
