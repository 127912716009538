import { memo } from 'react';

import { useAppModeConfig } from 'hooks/useAppModeConfig';
import { useAppModeSearchParamsHandler } from 'hooks/useAppModeSearchParamsHandler';
import { ROUTES } from 'platformsConstants';
import { Navigate } from 'react-router-dom';
import { selectIsLoggedIn } from 'redux/auth/selectors';
import { useAppSelector } from 'redux/hooks';

type Props = {
  component: JSX.Element;
  redirectTo?: string;
};

export const PrivateRoute = memo<Props>(
  ({ component: Component, redirectTo = '/login' }) => {
    // useAppModeSearchParamsHandler();

    const isLoggedIn = useAppSelector(selectIsLoggedIn);

    const { isRouteRestricted } = useAppModeConfig();

    return isLoggedIn ? (
      isRouteRestricted ? (
        <Navigate to={`/${ROUTES.HOME}`} />
      ) : (
        Component
      )
    ) : (
      <Navigate to={redirectTo} />
    );
  }
);
