import { api } from 'redux/api';
import { RequestType } from 'redux/platformAccounts/types';
import { Response } from 'redux/types';

import { AdAccountsResponse, DeleteBodyType, PutBodyType } from './types';

export const adAccountsApi = api.injectEndpoints({
  endpoints: builder => ({
    getAdAccounts: builder.query<AdAccountsResponse[], RequestType>({
      query: ({ platform, organization_id }) => ({
        url: `/${platform}/ads/accounts`,
        method: 'GET',
        params: { organization_id },
      }),
      providesTags: (result, error, arg) => [
        { type: 'adAccount', id: arg.platform },
      ],
      transformResponse: (response: Response<AdAccountsResponse[]>) =>
        response.content,
    }),

    postAdAccount: builder.mutation<AdAccountsResponse[], PutBodyType>({
      query: ({ platform, organization_id, ...body }) => ({
        url: `/${platform}/ads/accounts`,
        method: 'POST',
        body,
        params: { organization_id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'adAccount', id: arg.platform },
        {
          type: 'platformAccount',
          id: `${arg.platform}${arg.organization_id}`,
        },
        'subscription',
      ],
      transformResponse: (response: Response<AdAccountsResponse[]>) =>
        response.content,
      async onQueryStarted(
        { platform, organization_id },
        { dispatch, queryFulfilled }
      ) {
        const { data } = await queryFulfilled;
        dispatch(
          adAccountsApi.util.updateQueryData(
            'getAdAccounts',
            { platform, organization_id },
            draft => {
              draft.push(...data);
            }
          )
        );
      },
    }),

    putAdAccount: builder.mutation<AdAccountsResponse[], PutBodyType>({
      query: ({ platform, organization_id, ...body }) => ({
        url: `/${platform}/ads/accounts`,
        method: 'PUT',
        body,
        params: { organization_id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'adAccount', id: arg.platform },
        'subscription',
      ],
      transformResponse: (response: Response<AdAccountsResponse[]>) =>
        response.content,
    }),

    deleteAdAccount: builder.mutation<AdAccountsResponse[], DeleteBodyType>({
      query: ({ platform, organization_id, ...body }) => ({
        url: `/${platform}/ads/accounts`,
        method: 'DELETE',
        body,
        params: { organization_id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'adAccount', id: arg.platform },
        {
          type: 'platformAccount',
          id: `${arg.platform}${arg.organization_id}`,
        },
        { type: 'ai-rules', id: arg.organization_id },
        { type: 'urlTemplate', id: arg.platform },
        'subscription',
      ],
      transformResponse: (response: Response<AdAccountsResponse[]>) =>
        response.content,
    }),
  }),
});

export const {
  useGetAdAccountsQuery,
  usePutAdAccountMutation,
  usePostAdAccountMutation,
  useDeleteAdAccountMutation,
} = adAccountsApi;
