import { memo } from 'react';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

type PrimaryButtonProps = {
  type?: 'button' | 'submit' | 'reset' | undefined;
  name: string;
  onClick?: () => void;
  disabled?: boolean;
};

export const PrimaryButton = memo<PrimaryButtonProps>(({ name, ...props }) => {
  return (
    <StyledButton variant="contained" {...props}>
      {name}
    </StyledButton>
  );
});

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  '&:active': {
    backgroundColor: theme.palette.primary.main,
  },
}));
