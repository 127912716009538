import {
  forwardRef,
  memo,
  useImperativeHandle,
  useLayoutEffect,
  useState,
} from 'react';

import ClickAwayListener from '@mui/base/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import { styled, SxProps, Theme } from '@mui/material/styles';

export type PopoverHandle = {
  togglePopper: (event: React.MouseEvent<HTMLDivElement>) => void;
  hidePopper: () => void;
  anchor: HTMLElement | null;
};

type PopoverProps = {
  children: React.ReactElement;
  sx?: SxProps<Theme> | undefined;
  placement?: PopperPlacementType;
};

export const FilterPopper = memo(
  forwardRef<PopoverHandle, PopoverProps>(
    ({ children, sx, placement = 'bottom-end' }, ref) => {
      const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

      useImperativeHandle(ref, () => ({
        togglePopper,
        hidePopper,
        anchor: anchorEl,
      }));

      const togglePopper = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
      };

      const hidePopper = () => {
        setAnchorEl(null);
      };

      useLayoutEffect(() => {
        setTimeout(() => {
          const container = document.getElementById('boxToScrollDown');

          if (!container) return;

          container.scrollIntoView();
        }, 0);
      }, [anchorEl]);

      return (
        <Popper
          placement={placement}
          sx={{ zIndex: 5 }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
        >
          <StyledPaper sx={sx}>
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
              {children}
            </ClickAwayListener>
          </StyledPaper>
        </Popper>
      );
    }
  )
);

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: 385,
  maxHeight: 365,
  marginTop: '5px',
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden',
  borderRadius: '20px',
}));
