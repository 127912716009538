import { memo, useState } from 'react';

import { CircularProgress, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import betaIcon from 'assets/svg/betaIcon.svg';
import SplitButtonIcon from 'assets/svg/split_button.svg?react';
import { SplitButtonTooltip } from 'components/Tooltip/StyledTooltip';
import { SubscriptionLimitationTooltip } from 'components/Tooltip/SubscriptionLimitationTooltip';
import { useAppModeConfig } from 'hooks/useAppModeConfig';
import {
  COMING_SOON_PLATFORMS,
  EPlatforms,
  ICONS,
  ROUTES,
  TITLE,
} from 'platformsConstants';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AppMode } from 'redux/appMode/types';
import { useAppSelector } from 'redux/hooks';

type SplitButtonProps = {
  onClick: (platform: EPlatforms) => void;
  loading?: boolean;
};

export const SplitButton = memo<SplitButtonProps>(({ onClick, loading }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { platforms: Platforms, appMode } = useAppModeConfig();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const shouldBlock = useAppSelector(state => state.subscription.shouldBlock);
  const isMaxConnected = useAppSelector(
    state => state.subscription.isMaxConnected
  );

  const message = useAppSelector(state => state.subscription.bannerMessage);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (appMode === AppMode.SEARCH_TERMS) {
      onClick(EPlatforms.GOOGLE);
      return;
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    platform: EPlatforms
  ) => {
    onClick(platform);
  };

  const disabled =
    (shouldBlock || isMaxConnected) && pathname.includes(ROUTES.HOME);

  const buttonName = pathname.includes(ROUTES.HOME)
    ? t('buttons.split.adAccounts')
    : pathname.includes('ai-briefs')
    ? t('Create new AI brief')
    : t('buttons.split.templates');

  return (
    <>
      <SubscriptionLimitationTooltip
        show={disabled}
        placement="bottom-end"
        message={isMaxConnected ? t('banners.MAX_ALLOWED') : message}
      >
        <Button
          disabled={disabled}
          sx={{
            padding: '11px 8px 11px 20px',
            width: 242,
            pointerEvents: 'auto',
            justifyContent: 'space-between',
            '&.Mui-disabled path': { fill: '#84929E' },
          }}
          variant="contained"
          onClick={handleClick}
        >
          <Box>{buttonName}</Box>
          <Box sx={{ lineHeight: 0 }}>
            {loading ? <CircularProgress size={20} /> : <SplitButtonIcon />}
          </Box>
        </Button>
      </SubscriptionLimitationTooltip>

      <Popper sx={{ zIndex: 2 }} open={open} anchorEl={anchorEl} disablePortal>
        <BorderedPaper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList>
              {Platforms.map(platform => (
                <StyledMenuItem
                  key={platform}
                  onClick={event => handleMenuItemClick(event, platform)}
                >
                  <Box
                    component="img"
                    src={ICONS[platform]}
                    alt={ICONS[platform]}
                    height={29}
                    width={29}
                    marginRight={'20px'}
                  />
                  {platform === EPlatforms.GOOGLE ? (
                    <>
                      {TITLE[platform]}
                      <img
                        src={betaIcon}
                        alt="Google Beta Icon"
                        style={{ position: 'absolute', right: 0, bottom: 0 }}
                      />
                    </>
                  ) : (
                    TITLE[platform]
                  )}
                </StyledMenuItem>
              ))}
              {COMING_SOON_PLATFORMS.map(platform => (
                <StyledDisabledItem key={platform}>
                  <Box
                    component="img"
                    src={ICONS[platform]}
                    alt={ICONS[platform]}
                    height={29}
                    width={29}
                    marginRight={'20px'}
                  />
                  <SplitButtonTooltip title={t('tooltips.coming_soon')}>
                    <Typography>{TITLE[platform]}</Typography>
                  </SplitButtonTooltip>
                </StyledDisabledItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </BorderedPaper>
      </Popper>
    </>
  );
});

const BorderedPaper = styled(Paper)(() => ({
  width: 242,
  marginTop: '3px',
  overflow: 'hidden',
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: '13px 29px',
  borderBottom: '0.5px solid',
  borderColor: theme.palette.action.disabledBackground,
  '&:last-of-type': {
    borderBottom: 'none',
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const StyledDisabledItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '13px 29px',
  borderTop: '0.5px solid',
  borderColor: theme.palette.action.disabledBackground,
  '& .MuiTypography-root': {
    color: theme.palette.secondary.dark,
    margin: 0,
  },
}));
