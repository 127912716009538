import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EPlatforms, PLATFORMS } from 'platformsConstants';

import {
  AddHubSpotParamsPayload,
  DEFAULT_TEMPLATE_ID,
  DEFAULT_TEMPLATE_NAME,
  DeleteParamPayload,
  EditTemplatePayload,
  NewTemplateToEdit,
  SetParamNamePayload,
  SetParamValuePayload,
  TemplateStateType,
} from './types';
import { urlTemplateApi } from './urlTemplateApi';

const initialState: TemplateStateType = PLATFORMS.reduce(
  (acc, platform) => ({
    ...acc,
    [platform]: {},
  }),
  {} as TemplateStateType
);

export const trackingTemplateSlice = createSlice({
  name: 'trackingTemplate',
  initialState,
  reducers: {
    addTemplateToEdit(state, action: PayloadAction<NewTemplateToEdit>) {
      state[action.payload.platform][action.payload.id] = {
        name: action.payload.name,
        urlTemplate: Object.entries(action.payload.urlTemplate),
      };
    },

    changeTemplateName(
      state,
      action: PayloadAction<{
        id: string;
        name: string;
        platform: EPlatforms;
      }>
    ) {
      state[action.payload.platform][action.payload.id].name =
        action.payload.name;
    },

    clearDefaultDraft(state, action: PayloadAction<EPlatforms>) {
      delete state[action.payload][DEFAULT_TEMPLATE_ID];
    },

    clearDraft(
      state,
      action: PayloadAction<{ platform: EPlatforms; id: string }>
    ) {
      delete state[action.payload.platform][action.payload.id];
    },

    addNewParameter(state, action: PayloadAction<EditTemplatePayload>) {
      state[action.payload.platform][action.payload.id].urlTemplate.push([
        '',
        { value: '', isDynamic: false },
      ]);
    },

    addHubSpotParams(state, action: PayloadAction<AddHubSpotParamsPayload>) {
      state[action.payload.platform][action.payload.id].urlTemplate.push(
        ...action.payload.params
      );
    },

    deleteParameter(state, action: PayloadAction<DeleteParamPayload>) {
      state[action.payload.platform][action.payload.id].urlTemplate = state[
        action.payload.platform
      ][action.payload.id].urlTemplate.filter(
        item => item[0] !== action.payload.name
      );
    },

    setParamName(state, action: PayloadAction<SetParamNamePayload>) {
      state[action.payload.platform][action.payload.id].urlTemplate = state[
        action.payload.platform
      ][action.payload.id].urlTemplate.map(item => {
        if (item[0] === action.payload.oldValue) {
          return [action.payload.newValue, item[1]];
        } else {
          return item;
        }
      });
    },

    setParamValue(state, action: PayloadAction<SetParamValuePayload>) {
      state[action.payload.platform][action.payload.id].urlTemplate = state[
        action.payload.platform
      ][action.payload.id].urlTemplate.map(item => {
        if (item[0] === action.payload.name) {
          return [
            item[0],
            {
              ...item[1],
              value: action.payload.value,
              isDynamic: action.payload.isDynamic,
            },
          ];
        } else {
          return item;
        }
      });
    },
  },

  extraReducers: builder => {
    builder.addMatcher(
      urlTemplateApi.endpoints.getDefaultTemplate.matchFulfilled,
      (state, { payload }) => {
        state[payload.platform][DEFAULT_TEMPLATE_ID] = {
          name: DEFAULT_TEMPLATE_NAME,
          urlTemplate: Object.entries(payload.urlTemplate),
        };
      }
    );

    builder.addMatcher(
      urlTemplateApi.endpoints.getUrlTemplates.matchFulfilled,
      (state, action) => {
        const { platform } = action.meta.arg.originalArgs;
        const responseList = action.payload.map(({ id }) => id);
        const persistList = Object.keys(state[platform]);
        persistList.forEach(id => {
          if (!responseList.includes(id) && id !== DEFAULT_TEMPLATE_ID) {
            delete state[platform][id];
          }
        });
      }
    );
  },
});

export const {
  addNewParameter,
  deleteParameter,
  setParamName,
  setParamValue,
  addTemplateToEdit,
  changeTemplateName,
  clearDefaultDraft,
  clearDraft,
  addHubSpotParams,
} = trackingTemplateSlice.actions;

export const trackingTemplateReducer = trackingTemplateSlice.reducer;
