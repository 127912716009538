import { createSlice } from '@reduxjs/toolkit';

import { jobApi } from './jobApi';
import { JobStatus } from './types';

const initialState: {
  runningJobAdAccounts: Record<string, JobStatus>;
} = {
  runningJobAdAccounts: {},
};

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    resetJobState: () => initialState,
  },
  extraReducers: builder => {
    builder.addMatcher(
      jobApi.endpoints.runSearchTermValidationJob.matchPending,
      (state, action) => {
        const { adAccounts } = action.meta.arg.originalArgs;
        state.runningJobAdAccounts = adAccounts.reduce(
          (acc, id) => ({ ...acc, [id]: JobStatus.PENDING }),
          state.runningJobAdAccounts
        );
      }
    );
    builder.addMatcher(
      jobApi.endpoints.runSearchTermValidationJob.matchFulfilled,
      (state, action) => {
        const { adAccounts } = action.meta.arg.originalArgs;
        state.runningJobAdAccounts = adAccounts.reduce(
          (acc, id) => ({ ...acc, [id]: JobStatus.COMPLETED }),
          state.runningJobAdAccounts
        );
      }
    );
    builder.addMatcher(
      jobApi.endpoints.runSearchTermValidationJob.matchRejected,
      (state, action) => {
        const { adAccounts } = action.meta.arg.originalArgs;
        state.runningJobAdAccounts = adAccounts.reduce(
          (acc, id) => ({ ...acc, [id]: JobStatus.FAILED }),
          state.runningJobAdAccounts
        );
      }
    );
  },
});

export const { resetJobState } = jobSlice.actions;
export default jobSlice.reducer;
