import { FC } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { GoogleParsedAsset, RunDetailsItem } from 'redux/runHistory/types';
import { UPDATE_STATUS } from 'redux/runHistory/types';

import { CampaignTypeLabels, ErrorDescription } from '../../constants';

type DescriptionCellProps = {
  result: RunDetailsItem<GoogleParsedAsset>;
};

export const DescriptionCell: FC<DescriptionCellProps> = ({ result }) => {
  const { toUtm = '', status, errorType } = result;

  const isError = status === UPDATE_STATUS.ERROR && errorType;
  const isSkipped = status === UPDATE_STATUS.SKIPPED;
  const isNotSupported = status === UPDATE_STATUS.NOT_SUPPORTED;

  const { t } = useTranslation();

  if (isError) {
    const fullString = t(ErrorDescription[errorType]) || errorType;

    return (
      <Typography color="error.main" fontWeight={700}>
        {fullString}
      </Typography>
    );
  }

  if (isNotSupported) {
    return (
      <Typography>
        {t('details.not_supported_description')}{' '}
        {t(CampaignTypeLabels[result?.parsedAsset?.campaignType as string]) ||
          result?.parsedAsset?.campaignType}
      </Typography>
    );
  }

  if (!toUtm) return <Typography>{t('details.no_params')}</Typography>;

  const params = new URLSearchParams(toUtm);

  return (
    <>
      {Array.from(params).map(([key, value], index, arr) => (
        <Typography key={key} component="span">
          <Typography
            fontWeight={500}
            color={isSkipped ? 'inherit' : 'primary.main'}
            component="span"
          >
            {key}
          </Typography>
          {'='}
          <Typography
            fontWeight={500}
            color={isSkipped ? 'inherit' : 'success.light'}
            component="span"
            fontStyle="italic"
          >
            {value}
          </Typography>
          {index + 1 !== arr.length && '\u{200B}&'}
        </Typography>
      ))}
    </>
  );
};
