import { RootState } from 'redux/store';

export const selectUser = (state: RootState) => state.auth.user;

export const selectUserID = (state: RootState) => state.auth.user.user_id;

export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;

export const selectEmailError = (state: RootState) => state.auth.error.email;

export const selectPasswordError = (state: RootState) =>
  state.auth.error.password;
