import DropJobIcon from 'assets/svg/drop_job.svg?react';
import AutoSource from 'assets/svg/source_auto.svg?react';
import ManualSource from 'assets/svg/source_manual.svg?react';
import StatusError from 'assets/svg/status_error.svg?react';
import StatusNotSupported from 'assets/svg/status_not_supported.svg?react';
import {
  CAMPAIGN_FORMATS,
  CAMPAIGN_TYPES,
  CREATIVE_ERROR_TYPE,
  GOOGLE_SORT_KEYS,
  JOB_SOURCE,
  LINKEDIN_SORT_KEYS,
  SORT_BY,
  SORT_KEY,
  UPDATE_STATUS,
} from 'redux/runHistory/types';

export const Columns: Column<SORT_BY>[] = [
  {
    id: SORT_KEY.ACCOUNT_NAME,
    label: 'history_table_heading.ad_account',
    minWidth: 240,
  },
  {
    id: SORT_KEY.CREATED_AT,
    label: 'history_table_heading.run',
    minWidth: 180,
  },
  {
    id: UPDATE_STATUS.UPDATED,
    label: 'history_table_heading.updated',
    minWidth: 100,
  },
  {
    id: UPDATE_STATUS.SKIPPED,
    label: 'history_table_heading.skipped',
    minWidth: 100,
  },
  {
    id: UPDATE_STATUS.NOT_SUPPORTED,
    label: 'history_table_heading.not_supported',
    minWidth: 100,
  },
  {
    id: UPDATE_STATUS.PARTIALLY_UPDATED,
    label: 'history_table_heading.partially_updated',
    minWidth: 100,
  },
  {
    id: UPDATE_STATUS.ERROR,
    label: 'history_table_heading.error',
    minWidth: 100,
  },
  {
    id: SORT_KEY.TOTAL,
    label: 'history_table_heading.total',
    minWidth: 100,
  },
];

export const LinkedinDetailsColumns: Column<LINKEDIN_SORT_KEYS>[] = [
  {
    id: LINKEDIN_SORT_KEYS.CAMPAIGN_GROUP_NAME,
    label: 'history_table_heading.campaign_group_name',
    minWidth: 215,
  },
  {
    id: LINKEDIN_SORT_KEYS.CAMPAIGN_NAME,
    label: 'history_table_heading.campaign_name',
    minWidth: 250,
  },
  {
    id: LINKEDIN_SORT_KEYS.CREATIVE_NAME,
    label: 'history_table_heading.creative_name',
    minWidth: 200,
  },
];

export const GoogleDetailsColumns: Column<GOOGLE_SORT_KEYS>[] = [
  {
    id: GOOGLE_SORT_KEYS.CAMPAIGN_NAME,
    label: 'history_table_heading.campaign_name',
    minWidth: 215,
  },
  {
    id: GOOGLE_SORT_KEYS.AD_GROUP_NAME,
    label: 'history_table_heading.ad_group_name',
    minWidth: 250,
  },
  {
    id: GOOGLE_SORT_KEYS.AD_NAME,
    label: 'history_table_heading.ad_name',
    minWidth: 200,
  },
];

export interface Column<T> {
  id: T;
  label: string;
  minWidth?: number;
}

export const StatusIcons = {
  [UPDATE_STATUS.ERROR]: <StatusError />,
  [UPDATE_STATUS.NOT_SUPPORTED]: <StatusNotSupported />,
  [UPDATE_STATUS.UPDATED]: null,
  [UPDATE_STATUS.SKIPPED]: null,
  [UPDATE_STATUS.PARTIALLY_UPDATED]: null,
};

export const JOB_SOURCE_ICON = {
  [JOB_SOURCE.MANUAL]: <ManualSource />,
  [JOB_SOURCE.AUTO]: <AutoSource />,
  [JOB_SOURCE.DROP_JOB]: <DropJobIcon />,
};

export const RUN_TIME_FORMAT = 'MMM DD, YYYY HH:mm';

export const ErrorDescription = {
  [CREATIVE_ERROR_TYPE.NO_LANDING_PAGE]: 'run_error.NO_LANDING_PAGE',
  [CREATIVE_ERROR_TYPE.NO_URL_TEMPLATE]: 'run_error.NO_URL_TEMPLATE',
  [CREATIVE_ERROR_TYPE.NOT_PARSED_LANDING_PAGE]:
    'run_error.NOT_PARSED_LANDING_PAGE',
  [CREATIVE_ERROR_TYPE.NOT_CALCULATED_URL_TEMPLATE]:
    'run_error.NOT_CALCULATED_URL_TEMPLATE',
  [CREATIVE_ERROR_TYPE.UNABLE_TO_RESET]: 'run_error.UNABLE_TO_RESET',
  [CREATIVE_ERROR_TYPE.URL_TOO_LONG]: 'run_error.URL_TOO_LONG',
  [CREATIVE_ERROR_TYPE.NO_PERMISSIONS]: 'run_error.NO_PERMISSIONS',
  [CREATIVE_ERROR_TYPE.NO_CREATIVE_FOUND]: 'run_error.NO_CREATIVE_FOUND',
  [CREATIVE_ERROR_TYPE.INVALID_HREF]: 'run_error.INVALID_HREF',
  [CREATIVE_ERROR_TYPE.CONFLICT]: 'run_error.CONFLICT',
  [CREATIVE_ERROR_TYPE.FAILED_DEPENDENCY]: 'run_error.FAILED_DEPENDENCY',
  [CREATIVE_ERROR_TYPE.SERVER_ERROR]: 'run_error.SERVER_ERROR',
  [CREATIVE_ERROR_TYPE.UNKNOWN]: 'run_error.UNKNOWN',
};

export type FilterStatus = {
  status: UPDATE_STATUS | 'total';
  label: string;
};

export const LinkedinStatuses: FilterStatus[] = [
  { status: 'total', label: 'history_table_heading.all' },
  { status: UPDATE_STATUS.UPDATED, label: 'history_table_heading.updated' },
  { status: UPDATE_STATUS.SKIPPED, label: 'history_table_heading.skipped' },
  {
    status: UPDATE_STATUS.PARTIALLY_UPDATED,
    label: 'history_table_heading.partially_updated',
  },
  {
    status: UPDATE_STATUS.NOT_SUPPORTED,
    label: 'history_table_heading.not_supported',
  },
  { status: UPDATE_STATUS.ERROR, label: 'history_table_heading.error' },
];

export type StatusLabels = Record<UPDATE_STATUS, string>;

export const LinkedinStatusLabels: StatusLabels = {
  [UPDATE_STATUS.UPDATED]: 'history_table_heading.updated',
  [UPDATE_STATUS.SKIPPED]: 'history_table_heading.skipped',
  [UPDATE_STATUS.ERROR]: 'history_table_heading.error',
  [UPDATE_STATUS.PARTIALLY_UPDATED]: 'history_table_heading.partially_updated',
  [UPDATE_STATUS.NOT_SUPPORTED]: 'history_table_heading.not_supported',
};

export const CampaignFormatLabels: Record<string, string> = {
  [CAMPAIGN_FORMATS.TEXT_AD]: 'campaign_format.text_ad',
  [CAMPAIGN_FORMATS.SPOTLIGHT]: 'campaign_format.spotlight',
  [CAMPAIGN_FORMATS.SINGLE_VIDEO]: 'campaign_format.single_video',
  [CAMPAIGN_FORMATS.STANDARD_UPDATE]: 'campaign_format.standard_update',
  [CAMPAIGN_FORMATS.CAROUSEL]: 'campaign_format.carousel',
  [CAMPAIGN_FORMATS.FOLLOW_COMPANY]: 'campaign_format.follow_company',
  [CAMPAIGN_FORMATS.JOBS]: 'campaign_format.jobs',
  [CAMPAIGN_FORMATS.SPONSORED_INMAIL]: 'campaign_format.sponsored_inmail',
  [CAMPAIGN_FORMATS.SPONSORED_MESSAGE]: 'campaign_format.sponsored_message',
  [CAMPAIGN_FORMATS.SPONSORED_UPDATE_EVENT]:
    'campaign_format.sponsored_update_event',
  [CAMPAIGN_FORMATS.SPONSORED_UPDATE_NATIVE_DOCUMENT]:
    'campaign_format.sponsored_update_native_document',
  [CAMPAIGN_FORMATS.UNSUPPORTED]: 'campaign_format.unsupported',
};

export const CampaignTypeLabels: Record<string, string> = {
  [CAMPAIGN_TYPES.DISCOVERY]: 'campaign_type.discovery',
  [CAMPAIGN_TYPES.DISPLAY]: 'campaign_type.display',
  [CAMPAIGN_TYPES.SEARCH]: 'campaign_type.search',
  [CAMPAIGN_TYPES.SHOPPING]: 'campaign_type.shopping',
  [CAMPAIGN_TYPES.PERFORMANCE_MAX]: 'campaign_type.performance_max',
  [CAMPAIGN_TYPES.SMART]: 'campaign_type.smart',
  [CAMPAIGN_TYPES.UNKNOWN]: 'campaign_type.unknown',
  [CAMPAIGN_TYPES.UNSPECIFIED]: 'campaign_type.unspecified',
  [CAMPAIGN_TYPES.VIDEO]: 'campaign_type.video',
  [CAMPAIGN_TYPES.LOCAL]: 'campaign_type.local',
  [CAMPAIGN_TYPES.LOCAL_SERVICES]: 'campaign_type.local_services',
  [CAMPAIGN_TYPES.HOTEL]: 'campaign_type.hotel',
  [CAMPAIGN_TYPES.MULTI_CHANNEL]: 'campaign_type.multi_channel',
};
