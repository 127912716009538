import { useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/system';
import PaginateIconDouble from 'assets/svg/paginate_left_double.svg?react';
import PaginateIconSingle from 'assets/svg/paginate_left_single.svg?react';
import { useTranslation } from 'react-i18next';

type PaginationProps = {
  offset: number;
  setOffset: (offset: number) => void;
  total: number;
  limit: number;
};

const HEIGHT = 80;

export const Pagination: React.FC<PaginationProps> = ({
  offset,
  setOffset,
  total,
  limit,
}) => {
  const { t } = useTranslation();

  const initialPage = Math.floor(offset / limit) + 1;
  const maxPage = Math.ceil(total / limit);

  const [page, setPage] = useState(initialPage);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      setPage(1);
      return;
    }
    const number = parseInt(value);
    if (number < 1) {
      setPage(1);
    } else if (number > maxPage) {
      setPage(maxPage);
    } else {
      setPage(number);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newOffset = (page - 1) * limit;
    setOffset(newOffset);
  };

  const handleBlur = () => {
    setPage(initialPage);
  };

  const onExtremeBackClick = () => {
    const newOffset = 0;
    setOffset(newOffset);
    setPage(1);
  };

  const onBackClick = () => {
    const newOffset = offset - limit;
    setOffset(newOffset);
    setPage(prev => prev - 1);
  };

  const onForwardClick = () => {
    const newOffset = offset + limit;
    setOffset(newOffset);
    setPage(prev => prev + 1);
  };

  const onExtremeForwardClick = () => {
    const newOffset = (maxPage - 1) * limit;
    setOffset(newOffset);
    setPage(maxPage);
  };

  if (total <= limit) {
    return <Box height={23} />;
  }

  return (
    <GridContainer>
      <ButtonsContainer>
        <Box>
          <StyledButton onClick={onExtremeBackClick} disabled={page <= 1}>
            <PaginateIconDouble />
          </StyledButton>
          <StyledButton onClick={onBackClick} disabled={page <= 1}>
            <PaginateIconSingle />
          </StyledButton>
        </Box>
        <Box sx={{ transform: 'rotate(180deg)' }}>
          <StyledButton
            onClick={onExtremeForwardClick}
            disabled={page >= maxPage}
          >
            <PaginateIconDouble />
          </StyledButton>
          <StyledButton onClick={onForwardClick} disabled={page >= maxPage}>
            <PaginateIconSingle />
          </StyledButton>
        </Box>
      </ButtonsContainer>
      <InputContainer>
        {t('run_history.pagination.page')}{' '}
        <FormControl component={'form'} onSubmit={onSubmit}>
          <StyledInput
            value={page}
            onChange={handleInputChange}
            onBlur={handleBlur}
            type="number"
          />
        </FormControl>
        {t('run_history.pagination.of')} {maxPage}
      </InputContainer>
    </GridContainer>
  );
};

const GridContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto 1fr',
  alignItems: 'center',
  height: HEIGHT,
}));

const ButtonsContainer = styled(Box)(() => ({
  gridColumn: 2,
  display: 'flex',
  gap: '80px',
  '& > div': {
    display: 'flex',
    gap: '10px',
  },
}));

const StyledButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  '&.Mui-disabled path': {
    fill: theme.palette.secondary.dark,
  },
}));

const InputContainer = styled(Box)(() => ({
  gridColumn: 3,
  display: 'flex',
  marginLeft: 'auto',
  alignItems: 'center',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  width: 60,
  margin: '6px',
  height: 35,
  borderRadius: '8px',
  border: `1px solid ${theme.palette.secondary.main}`,
  '& input': {
    padding: 0,
    textAlign: 'center',
  },
}));
