import { AIRulesCategory, AIRulesSource } from 'redux/searchTerms/types';

export enum TABS {
  OVERVIEW = 'overview',
  PRODUCTS = 'products',
  RULES = 'rules',
  EXAMPLES = 'examples',
}

export const TabLabels = {
  [TABS.OVERVIEW]: 'Overview',
  [TABS.PRODUCTS]: 'Services or products',
  [TABS.RULES]: 'Rules',
  [TABS.EXAMPLES]: 'Extra examples',
};

export const NEW_BRIEF_PATH = 'new-brief';

export const NewSearchTerm = {
  searchTerm: '',
  isExclude: true,
  category: AIRulesCategory.PRODUCT,
  source: AIRulesSource.User,
  updatedAt: null,
  inUse: true,
};

export const CategoryOptions = [
  {
    value: AIRulesCategory.PRODUCT,
    label: 'ai_rules.extra_examples.select_value_1',
  },
  {
    value: AIRulesCategory.BRAND,
    label: 'ai_rules.extra_examples.select_value_2',
  },
];

export enum SearchTermTableHeadings {
  TERM = 'term',
  EXCLUDE = 'exclude',
  CATEGORY = 'category',
  SOURCE = 'source',
  DATE = 'date',
  IN_USE = 'in_use',
}

export enum SearchTermFilterKeys {
  EXCLUDE = 'exclude',
  CATEGORY = 'category',
  SOURCE = 'source',
  MODIFY_DATE = 'modifyDate',
  IN_USE = 'inUse',
}

export type FilterType = {
  [SearchTermFilterKeys.EXCLUDE]: boolean | null;
  [SearchTermFilterKeys.CATEGORY]: AIRulesCategory | null;
  [SearchTermFilterKeys.SOURCE]: AIRulesSource | null;
  [SearchTermFilterKeys.MODIFY_DATE]: {
    from: Date | null;
    to: Date | null;
  };
  [SearchTermFilterKeys.IN_USE]: boolean | null;
};

export const FilterLabels = {
  [SearchTermFilterKeys.EXCLUDE]:
    'ai_rules.extra_examples.table_heading.exclude',
  [SearchTermFilterKeys.CATEGORY]:
    'ai_rules.extra_examples.table_heading.category',
  [SearchTermFilterKeys.SOURCE]: 'ai_rules.extra_examples.table_heading.source',
  [SearchTermFilterKeys.MODIFY_DATE]:
    'ai_rules.extra_examples.table_heading.date',
  [SearchTermFilterKeys.IN_USE]: 'ai_rules.extra_examples.table_heading.in_use',
};

export const BooleanOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const SourceOptions = [
  { value: AIRulesSource.AIAssistant, label: 'AI Assistant' },
  { value: AIRulesSource.User, label: 'User' },
];

export const FilterOptions = {
  [SearchTermFilterKeys.EXCLUDE]: BooleanOptions,
  [SearchTermFilterKeys.CATEGORY]: CategoryOptions,
  [SearchTermFilterKeys.SOURCE]: SourceOptions,
  [SearchTermFilterKeys.MODIFY_DATE]: [],
  [SearchTermFilterKeys.IN_USE]: BooleanOptions,
};

export enum ShownMenuFilter {
  MENU = 'menu',
  DATES = 'dates',
}

export enum CompetitorsError {
  REQUIRED = 'Action required',
  NOT_ENOUGH = 'At least 5 competitor names are needed',
  TOO_MANY = 'A maximum of 15 competitors is allowed. Please make sure to keep only the key competitors in the list',
}

export enum ProductsError {
  REQUIRED = 'Action required',
  TOO_MANY = 'A maximum of 15 services or products is allowed. Please make sure to keep only the key services or products in the list',
}

export const ExtraExampleSourceLabels = {
  [AIRulesSource.AIAssistant]: 'AI Assistant',
  [AIRulesSource.User]: 'User',
};
