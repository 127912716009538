import { memo } from 'react';

import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import buttonGoogle from 'assets/images/btn_google.png';

type GooglePopupButtonProps = {
  onClick: () => void;
};

export const GooglePopupButton = memo<GooglePopupButtonProps>(({ onClick }) => {
  return (
    <ButtonBase onClick={onClick}>
      <Box component="img" src={buttonGoogle} width="190px" />
    </ButtonBase>
  );
});
