import {
  createCustomEvent,
  createSimpleEvent,
  DataLayerEventName,
  getMethod,
} from 'gtm';
import { api } from 'redux/api';
import { organizationApi } from 'redux/organization/organizationApi';
import { ErrorsType, Response } from 'redux/types';
import { workspaceApi } from 'redux/workspace/workspaceApi';

import { chooseOrganization } from './authSlice';
import {
  Credentials,
  LoginType,
  recoveryPasswordBody,
  TokenDecoded,
  UserInfo,
  VerifyTokenBody,
} from './types';

export const authApi = api.injectEndpoints({
  endpoints: builder => ({
    getUserByEmail: builder.query<ErrorsType, string>({
      query: email => ({
        url: '/api/v1/users/check-email',
        method: 'GET',
        params: { email },
      }),
      transformResponse: (response: Response<unknown>) => response.errors,
    }),

    changeUserInfo: builder.mutation<UserInfo, Partial<UserInfo>>({
      query: ({ id, ...body }) => ({
        url: `/api/v1/users/${id}`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: result => [{ type: 'user', id: result?.id }],
      transformResponse: (response: Response<UserInfo>) => response.content,
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          createSimpleEvent(DataLayerEventName.UPDATE_PROFILE);
        } catch (error) {
          console.log('error had been caught');
        }
      },
    }),

    getUserInfo: builder.query<UserInfo, string>({
      query: id => ({
        url: `/api/v1/users/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [{ type: 'user', id: arg }],
      transformResponse: (response: Response<UserInfo>) => response.content,
    }),

    login: builder.mutation<UserInfo, Partial<Credentials>>({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ invitationToken, ...credentials }) => ({
        url: '/api/v1/users/login',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: result => [{ type: 'user', id: result?.id }],
      transformResponse: (response: Response<UserInfo>) => response.content,
      async onQueryStarted(
        { invitationToken, type = LoginType.BASIC },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: user } = await queryFulfilled;

          if (invitationToken) {
            await dispatch(
              organizationApi.endpoints.handleInvitation.initiate(
                invitationToken
              )
            );
          } else {
            createCustomEvent({
              event: DataLayerEventName.LOGIN,
              user_id: user.id,
              method: getMethod(type),
            });

            const { data: organizations } = await dispatch(
              organizationApi.endpoints.getOrganizationByUser.initiate(
                user.id,
                { forceRefetch: true }
              )
            );
            dispatch(chooseOrganization(organizations?.[0].id || ''));
            await dispatch(
              workspaceApi.endpoints.getWorkspaceByOrgId.initiate(
                organizations?.[0].id || '',
                { forceRefetch: true }
              )
            );
          }
        } catch (error) {
          console.log('error had been caught');
        }
      },
    }),

    logout: builder.mutation<void, string>({
      query: user_id => ({
        url: `/api/v1/users/${user_id}/logout`,
        method: 'POST',
      }),
    }),

    register: builder.mutation<UserInfo, Partial<Credentials>>({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ invitationToken, ...credentials }) => ({
        url: '/api/v1/users/register',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: result => [{ type: 'user', id: result?.id }],
      transformResponse: (response: Response<UserInfo>) => response.content,
      async onQueryStarted({ invitationToken }, { dispatch, queryFulfilled }) {
        try {
          const { data: user } = await queryFulfilled;
          if (invitationToken) {
            await dispatch(
              organizationApi.endpoints.handleInvitation.initiate(
                invitationToken
              )
            );
          } else {
            const { data: organizations } = await dispatch(
              organizationApi.endpoints.getOrganizationByUser.initiate(
                user.id,
                { forceRefetch: true }
              )
            );
            dispatch(chooseOrganization(organizations?.[0].id || ''));
            await dispatch(
              workspaceApi.endpoints.getWorkspaceByOrgId.initiate(
                organizations?.[0].id || '',
                { forceRefetch: true }
              )
            );
          }
        } catch (error) {
          console.log('error had been caught');
        }
      },
    }),

    forgotPassword: builder.mutation<Response<UserInfo>, string>({
      query: email => ({
        url: `/api/v1/users/forgot-password`,
        method: 'POST',
        params: { email },
      }),
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
          createSimpleEvent(DataLayerEventName.PASSWORD_RESET);
        } catch (error) {
          console.log('error had been caught');
        }
      },
    }),

    recoverPassword: builder.mutation<Response<UserInfo>, recoveryPasswordBody>(
      {
        query: body => ({
          url: `/api/v1/users/recover-password`,
          method: 'POST',
          body: body,
        }),
      }
    ),

    verifyToken: builder.mutation<Response<TokenDecoded>, VerifyTokenBody>({
      query: body => ({
        url: `/api/v1/users/verify-token`,
        method: 'POST',
        body: body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useRecoverPasswordMutation,
  useVerifyTokenMutation,
  useGetUserInfoQuery,
  useChangeUserInfoMutation,
  useLazyGetUserInfoQuery,
  useLazyGetUserByEmailQuery,
} = authApi;
