import { memo } from 'react';

import { Typography } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip';

import { StyledLimitationTooltip } from './StyledTooltip';

type SubscriptionLimitationTooltipProps = {
  children: React.ReactElement;
  show: boolean;
  placement: TooltipProps['placement'];
  message?: string;
};

export const SubscriptionLimitationTooltip =
  memo<SubscriptionLimitationTooltipProps>(
    ({ children, show, placement, message }) => {
      return (
        <StyledLimitationTooltip
          title={show && <Typography align="center">{message}</Typography>}
          placement={placement}
          sx={{
            '& .MuiTooltip-tooltipPlacementBottom > .MuiTooltip-arrow::before':
              {
                position: 'absolute',
                left: 108,
              },
            '& .MuiTooltip-tooltipPlacementBottom > .MuiTooltip-arrow': {
              overflow: 'visible',
            },
          }}
        >
          <Typography> {children}</Typography>
        </StyledLimitationTooltip>
      );
    }
  );
