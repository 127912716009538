import { Suspense } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as Sentry from '@sentry/react';
import oops_image from 'assets/images/oops_image.png';
import { AuthContainer } from 'components/AuthContainer/AuthContainer';
import { PrimaryButton } from 'components/PrimaryButton/PrimaryButton';
import { ROUTES } from 'platformsConstants';
import { useCookies } from 'react-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Outlet, useRouteError } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

import { ErrorStyledBox } from './ErrorStyledBox';

export const RouterErrorBoundary = () => {
  const error = useRouteError() as Error;
  const user = useAppSelector(state => state.auth.user);

  Sentry.captureException(error, { user });

  return <ErrorPage error={error} />;
};

export const ErrorBoundaryLayout = () => {
  const user = useAppSelector(state => state.auth.user);

  return (
    <ErrorBoundary
      fallbackRender={ErrorPage}
      onError={error => Sentry.captureException(error, { user })}
    >
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    </ErrorBoundary>
  );
};

export const ErrorPage = ({ error }: { error?: Error }) => {
  const { t } = useTranslation();

  const [cookies] = useCookies(['showError']);

  const navigate = useNavigate();

  return (
    <AuthContainer>
      <ErrorStyledBox>
        <Typography variant="h2">{t('appError.title')}</Typography>
        <Typography variant="body2">{t('appError.subtitle')}</Typography>
        <Box component="img" alt="error image" src={oops_image} />
        <PrimaryButton
          onClick={() => {
            navigate(`/${ROUTES.HOME}`);
            window.location.reload();
          }}
          name={t('buttons.backHome')}
        />
        {cookies.showError && <Box mt="20px">{error?.stack}</Box>}
      </ErrorStyledBox>
    </AuthContainer>
  );
};
