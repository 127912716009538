import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StateModel } from 'redux/auth/types';
import { organizationApi } from 'redux/organization/organizationApi';
import { ErrorsType } from 'redux/types';
import { workspaceApi } from 'redux/workspace/workspaceApi';

import { authApi } from './authApi';

const initialState: StateModel = {
  user: {
    user_id: '',
    organization_id: '',
    workspace_id: '',
  },
  isLoggedIn: false,
  error: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetErrors(state, action: PayloadAction<keyof ErrorsType | undefined>) {
      action.payload ? delete state.error[action.payload] : (state.error = {});
    },

    logOut: () => initialState,

    chooseOrganization(state, action: PayloadAction<string>) {
      state.user.organization_id = action.payload;
      state.user.workspace_id = '';
    },
  },

  extraReducers: builder => {
    builder
      .addMatcher(
        authApi.endpoints.getUserByEmail.matchFulfilled,
        (state, { payload }) => {
          state.error = payload;
        }
      )

      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.user.user_id = payload.id;
        }
      )

      .addMatcher(
        authApi.endpoints.login.matchRejected,
        (state, { payload }) => {
          const { errors } = (payload?.data as any) || {};
          if (errors) {
            state.error = errors as ErrorsType;
          }
        }
      )

      .addMatcher(
        authApi.endpoints.register.matchFulfilled,
        (state, { payload }) => {
          state.user.user_id = payload.id;
        }
      )

      .addMatcher(
        authApi.endpoints.register.matchRejected,
        (state, { payload }) => {
          const { errors } = (payload?.data as any) || {};
          if (errors) {
            state.error = errors as ErrorsType;
          }
        }
      )

      .addMatcher(
        authApi.endpoints.forgotPassword.matchRejected,
        (state, { payload }) => {
          const { errors } = (payload?.data as any) || {};
          if (errors) {
            state.error = errors as ErrorsType;
          }
        }
      )

      .addMatcher(
        workspaceApi.endpoints.getWorkspaceByOrgId.matchFulfilled,
        (state, { payload }) => {
          state.user.workspace_id = payload[0].id;
          state.isLoggedIn = true;
        }
      )

      .addMatcher(authApi.endpoints.logout.matchFulfilled, () => initialState)

      .addMatcher(
        authApi.endpoints.recoverPassword.matchFulfilled,
        () => initialState
      )

      .addMatcher(
        organizationApi.endpoints.handleInvitation.matchFulfilled,
        (state, action) => {
          state.user.organization_id = action.payload?.organization?.id;
          state.user.workspace_id = '';
        }
      );
  },
});

export const { resetErrors, logOut, chooseOrganization } = authSlice.actions;
export const authReducer = authSlice.reducer;
