import { EPlatforms } from 'platformsConstants';
import { api } from 'redux/api';
import { Response } from 'redux/types';

import {
  GetGoogleRunDetailsParams,
  GetLinkedinRunDetailsParams,
  GetRunHistoryParams,
  GoogleGetRunDetailsDTO,
  GoogleParsedAsset,
  GoogleRunDetailsFilter,
  HistoryAdAccount,
  LinkedinGetRunDetailsDTO,
  LinkedinParsedAsset,
  LinkedinRunDetailsFilter,
  PlatformFormat,
  RunDetailsData,
  RunDetailsMetaData,
  RunDetailsMetaDTO,
  RunHistoryData,
} from './types';

export const runHistoryApi = api.injectEndpoints({
  endpoints: builder => ({
    getRunHistory: builder.query<RunHistoryData, GetRunHistoryParams>({
      query: params => ({
        url: '/api/v1/run-history',
        method: 'GET',
        params,
      }),
      providesTags: ['runHistory'],
      transformResponse: (response: Response<RunHistoryData>) =>
        response?.content,
    }),

    getAccounts: builder.query<HistoryAdAccount[], { organization_id: string }>(
      {
        query: params => ({
          url: '/api/v1/run-history/accounts',
          method: 'GET',
          params,
        }),
        providesTags: ['runHistory'],
        transformResponse: (response: Response<HistoryAdAccount[]>) =>
          response?.content,
      }
    ),

    getLinkedinDetails: builder.query<
      RunDetailsData<LinkedinGetRunDetailsDTO, LinkedinParsedAsset>,
      GetLinkedinRunDetailsParams
    >({
      query: params => ({
        url: '/api/v1/run-history/linkedin/run-details',
        method: 'GET',
        params,
      }),
      providesTags: ['runDetails'],
      transformResponse: (
        response: Response<
          RunDetailsData<LinkedinGetRunDetailsDTO, LinkedinParsedAsset>
        >
      ) => response?.content,
    }),

    getGoogleDetails: builder.query<
      RunDetailsData<GoogleGetRunDetailsDTO, GoogleParsedAsset>,
      GetGoogleRunDetailsParams
    >({
      query: params => ({
        url: '/api/v1/run-history/google/run-details',
        method: 'GET',
        params,
      }),
      providesTags: ['runDetails'],
      transformResponse: (
        response: Response<
          RunDetailsData<GoogleGetRunDetailsDTO, GoogleParsedAsset>
        >
      ) => response?.content,
    }),

    getLinkedinDetailsMetadata: builder.query<
      RunDetailsMetaData<LinkedinRunDetailsFilter>,
      RunDetailsMetaDTO
    >({
      query: params => ({
        url: '/api/v1/run-history/linkedin/run-metadata',
        method: 'GET',
        params,
      }),
      providesTags: ['runDetails'],
      transformResponse: (
        response: Response<RunDetailsMetaData<LinkedinRunDetailsFilter>>
      ) => response?.content,
    }),

    getGoogleDetailsMetadata: builder.query<
      RunDetailsMetaData<GoogleRunDetailsFilter>,
      RunDetailsMetaDTO
    >({
      query: params => ({
        url: '/api/v1/run-history/google/run-metadata',
        method: 'GET',
        params,
      }),
      providesTags: ['runDetails'],
      transformResponse: (
        response: Response<RunDetailsMetaData<GoogleRunDetailsFilter>>
      ) => response?.content,
    }),

    getNotSupported: builder.query<
      PlatformFormat<EPlatforms>,
      { platform: EPlatforms }
    >({
      query: params => ({
        url: '/api/v1/run-history/not-supported',
        method: 'GET',
        params,
      }),
      providesTags: ['notSupported'],
      transformResponse: (response: Response<PlatformFormat<EPlatforms>>) =>
        response?.content,
    }),
  }),
});

export const {
  useGetRunHistoryQuery,
  useGetAccountsQuery,
  useGetLinkedinDetailsQuery,
  useGetGoogleDetailsQuery,
  useGetNotSupportedQuery,
  useGetLinkedinDetailsMetadataQuery,
  useGetGoogleDetailsMetadataQuery,
} = runHistoryApi;
