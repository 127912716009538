import { EPlatforms } from 'platformsConstants';
import {
  GetContentType,
  LastRunSummary,
  NextJobType,
  PeriodSummary,
} from 'redux/job/types';

export type AdAccountType = {
  resourceName: string;
  id: number;
  enabled: boolean;
  isSearchTermsValidationEnabled: boolean;
  connectionStatus: PlatformAccountConnectionStatus;
  connectedByUser: string;
  connectedAt: string;
  currentTemplate: CurrentTemplateType;
  _id: string;
  managerAccountId: string;
  managerRessourceName: string;
  managerAccountName: string;
  user: { email: string; firstName: string; lastName: string } | null;
};

export type CurrentTemplateType = {
  name: string;
  id: string;
} | null;

export interface BoostedAdAccountType extends AdAccountType {
  usersId: string[];
  userInfoId: string[];
  lastRun?: GetContentType;
  nextRun?: NextJobType;
  periodSummary?: PeriodSummary;
  lastRunSearchTerm?: LastRunSummary;
}

export type AdAccountsResponse = {
  _id: string;
  workspaceId: string;
  organizationId: string;
  userInfoId: string;
  adAccounts: AdAccountType[];
  platform: string;
  _v: number;
};

export type PutAdAccountType = {
  userInfoId?: string;
  adAccounts: PutAdAccount[];
};

export type PutAdAccount = Partial<AdAccountType>;

export type DeleteAdAccountType = {
  userInfoId?: string;
  adAccounts: Pick<AdAccountType, 'id'>[];
};

export enum PlatformAccountConnectionStatus {
  CONNECTED = 'CONNECTED',
  ERROR = 'ERROR',
  NOT_CONNECTED = 'NOT_CONNECTED',
}

export type PutBodyType = {
  organization_id: string;
  platform: EPlatforms;
  usersAdAccounts: PutAdAccountType[];
};

export type DeleteBodyType = {
  organization_id: string;
  platform: EPlatforms;
  usersAdAccounts: DeleteAdAccountType[];
};
