import { api } from 'redux/api';
import { Response } from 'redux/types';

import {
  AIRules,
  AIRulesSource,
  Brand,
  CreateAIRulesDto,
  CreateBrandDto,
  SearchTerm,
  SingleBrandAIRules,
  SingleBrandElements,
  SuggestBrandElementsDto,
  TransformedSingleBrandAIRules,
} from './types';

export const searchTermsApi = api.injectEndpoints({
  endpoints: builder => ({
    suggestBrandElements: builder.mutation<
      SingleBrandElements,
      SuggestBrandElementsDto
    >({
      query: ({ organization_id, ...body }) => ({
        url: `/api/v1/searchTerms/brands/suggest-brand-elements`,
        method: 'POST',
        body,
        params: { organization_id },
      }),
      transformResponse: (response: Response<SingleBrandElements>) =>
        response.content,
    }),

    suggestBrandAIRules: builder.mutation<
      TransformedSingleBrandAIRules,
      SingleBrandElements & { organization_id: string }
    >({
      query: ({ organization_id, ...body }) => ({
        url: `/api/v1/searchTerms/brands/suggest-brand-ai-rules`,
        method: 'POST',
        body,
        params: { organization_id },
      }),
      transformResponse: (response: Response<SingleBrandAIRules>) => {
        const excludedTerms: Array<SearchTerm> =
          response.content.excluded_search_terms.map(term => {
            return {
              searchTerm: term.search_term,
              isExclude: true,
              category: term.category,
              source: AIRulesSource.AIAssistant,
              updatedAt: new Date().toISOString(),
              id: Math.random().toString(),
              inUse: true,
            };
          });

        const perfectTerms: Array<SearchTerm> =
          response.content.perfect_search_terms.map(term => {
            return {
              searchTerm: term.search_term,
              isExclude: false,
              category: term.category,
              source: AIRulesSource.AIAssistant,
              updatedAt: new Date().toISOString(),
              id: Math.random().toString(),
              inUse: true,
            };
          });

        const searchTerms = [...excludedTerms, ...perfectTerms];

        return {
          general_rules: response.content.general_rules,
          search_terms: searchTerms,
        };
      },
    }),

    postBrand: builder.mutation<Brand, CreateBrandDto>({
      query: body => ({
        url: `/api/v1/searchTerms/brands`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: Response<Brand>) => response.content,
      invalidatesTags: (result, error, arg) => [
        { type: 'brand', id: arg.organizationId },
        { type: 'brand', id: result?.id },
      ],
    }),

    updateBrand: builder.mutation<Brand, CreateBrandDto & { id: string }>({
      query: ({ id, ...body }) => ({
        url: `/api/v1/searchTerms/brands/${id}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (response: Response<Brand>) => response.content,
      invalidatesTags: (result, error, arg) => [
        { type: 'brand', id: arg.organizationId },
        { type: 'brand', id: arg.id },
      ],
    }),

    patchBrand: builder.mutation<
      Brand,
      Partial<CreateBrandDto> & { id: string; organizationId: string }
    >({
      query: ({ id, ...body }) => ({
        url: `/api/v1/searchTerms/brands/${id}`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: Response<Brand>) => response.content,
      invalidatesTags: (result, error, arg) => [
        { type: 'brand', id: arg.organizationId },
        { type: 'brand', id: arg.id },
      ],
    }),

    getOneBrand: builder.query<Brand, { id: string }>({
      query: ({ id }) => ({
        url: `/api/v1/searchTerms/brands/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [{ type: 'brand', id: arg.id }],
      transformResponse: (response: Response<Brand>) => response.content,
    }),

    getAllBrands: builder.query<Array<Brand>, { organization_id: string }>({
      query: ({ organization_id }) => ({
        url: `/api/v1/searchTerms/brands`,
        method: 'GET',
        params: { organization_id },
      }),
      providesTags: (result, error, arg) => [
        { type: 'brand', id: arg.organization_id },
      ],
      transformResponse: (response: Response<Array<Brand>>) => response.content,
    }),

    postAIRules: builder.mutation<AIRules, CreateAIRulesDto>({
      query: body => ({
        url: `/api/v1/searchTerms/ai-rules`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: Response<AIRules>) => response.content,
      invalidatesTags: result => [
        { type: 'ai-rules', id: result?.brandId },
        { type: 'ai-rules', id: result?.organizationId },
      ],
    }),

    updateAIRules: builder.mutation<AIRules, CreateAIRulesDto>({
      query: ({ brandId, ...body }) => ({
        url: `/api/v1/searchTerms/ai-rules/${brandId}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (response: Response<AIRules>) => response.content,
      invalidatesTags: result => [
        { type: 'ai-rules', id: result?.brandId },
        { type: 'ai-rules', id: result?.organizationId },
      ],
    }),

    getOneBrandAIRules: builder.query<AIRules, { brandId: string }>({
      query: ({ brandId }) => ({
        url: `/api/v1/searchTerms/ai-rules/${brandId}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: 'ai-rules', id: arg.brandId },
      ],
      transformResponse: (response: Response<AIRules>) => response.content,
    }),

    getAllAIRules: builder.query<Array<AIRules>, { organization_id: string }>({
      query: ({ organization_id }) => ({
        url: `/api/v1/searchTerms/ai-rules`,
        method: 'GET',
        params: { organization_id },
      }),
      providesTags: (result, error, arg) => [
        { type: 'ai-rules', id: arg.organization_id },
      ],
      transformResponse: (response: Response<Array<AIRules>>) =>
        response.content,
    }),

    deleteBrands: builder.mutation<
      void,
      { brandIds: string[]; organization_id: string }
    >({
      query: ({ brandIds, organization_id }) => ({
        url: `/api/v1/searchTerms/brands`,
        method: 'DELETE',
        params: { organization_id },
        body: { brandIds },
      }),
      invalidatesTags: () => [{ type: 'brand' }, { type: 'ai-rules' }],
    }),
  }),
});

export const {
  useSuggestBrandElementsMutation,
  useSuggestBrandAIRulesMutation,
  usePostBrandMutation,
  useUpdateBrandMutation,
  useGetOneBrandQuery,
  useGetAllBrandsQuery,
  usePatchBrandMutation,
  usePostAIRulesMutation,
  useUpdateAIRulesMutation,
  useGetOneBrandAIRulesQuery,
  useGetAllAIRulesQuery,
  useDeleteBrandsMutation,
} = searchTermsApi;
