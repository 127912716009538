import { Dispatch, FC, SetStateAction } from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import VectorLeft from 'assets/svg/vector_left.svg?react';
import VectorRight from 'assets/svg/vector_right.svg?react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { JOB_SOURCE, JobBriefInfo } from 'redux/runHistory/types';

import { RUN_TIME_FORMAT } from '../constants';

type JobNavigationProps = {
  prevJobInfo: JobBriefInfo;
  nextJobInfo: JobBriefInfo;
  setJobId: Dispatch<SetStateAction<string>>;
  setCreatedAt: Dispatch<SetStateAction<string>>;
  setJobSource: Dispatch<SetStateAction<JOB_SOURCE>>;
};

export const JobNavigation: FC<JobNavigationProps> = ({
  prevJobInfo,
  nextJobInfo,
  setJobId,
  setCreatedAt,
  setJobSource,
}) => {
  const { t } = useTranslation();

  const isPrevJobExist = Boolean(
    prevJobInfo.createdAt && prevJobInfo.jobId && prevJobInfo?.jobSource
  );
  const isNextJobExist = Boolean(
    nextJobInfo.createdAt && nextJobInfo.jobId && nextJobInfo?.jobSource
  );

  const onPrevJobClick = () => {
    if (isPrevJobExist) {
      setJobId(prevJobInfo?.jobId || '');
      setCreatedAt(moment(prevJobInfo?.createdAt).toISOString());
      setJobSource(prevJobInfo?.jobSource || JOB_SOURCE.MANUAL);
    }
  };

  const onNextJobClick = () => {
    if (isNextJobExist) {
      setJobId(nextJobInfo?.jobId || '');
      setCreatedAt(moment(nextJobInfo?.createdAt).toISOString());
      setJobSource(nextJobInfo?.jobSource || JOB_SOURCE.MANUAL);
    }
  };

  return (
    <Container>
      <Box
        onClick={onPrevJobClick}
        sx={{
          width: 140,
          cursor: isPrevJobExist ? 'pointer' : 'not-allowed',
          opacity: isPrevJobExist ? 1 : 0.5,
        }}
      >
        <Typography mb="7px">
          <VectorLeft /> {t('details.prev_run')}
        </Typography>
        {isPrevJobExist && (
          <Typography>
            {moment(prevJobInfo.createdAt).format(RUN_TIME_FORMAT)}
          </Typography>
        )}
      </Box>
      <Box
        onClick={onNextJobClick}
        sx={{
          width: 140,
          textAlign: 'right',
          cursor: isNextJobExist ? 'pointer' : 'not-allowed',
          opacity: isNextJobExist ? 1 : 0.5,
        }}
      >
        <Typography mb="7px">
          {t('details.next_run')} <VectorRight />
        </Typography>
        {isNextJobExist && (
          <Typography>
            {moment(nextJobInfo.createdAt).format(RUN_TIME_FORMAT)}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

const Container = styled(Box)({
  gap: '48px',
  position: 'absolute',
  right: 24,
  display: 'flex',
});
