import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const ErrorStyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .MuiTypography-root': {
    maxWidth: 500,
    marginBottom: 13,
    textAlign: 'center',
  },
  '& img': {
    [theme.breakpoints.down('md')]: {
      marginTop: 39,
      marginBottom: 30,
      width: 169,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 41,
      marginBottom: 35,
      width: 302,
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: 73,
      marginBottom: 40,
      width: 348,
    },
  },
}));
