import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppMode } from './types';

export const appModeSlice = createSlice({
  name: 'appMode',
  initialState: {
    appMode: AppMode.AUTO_UTM as AppMode,
  },
  reducers: {
    setAppMode(state, action: PayloadAction<AppMode>) {
      state.appMode = action.payload;
    },

    switchAppMode(state) {
      state.appMode =
        state.appMode === AppMode.AUTO_UTM
          ? AppMode.SEARCH_TERMS
          : AppMode.AUTO_UTM;
    },
  },
});

export const { setAppMode, switchAppMode } = appModeSlice.actions;
export const appModeReducer = appModeSlice.reducer;
