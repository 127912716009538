import { api } from 'redux/api';
import { Response } from 'redux/types';

import {
  DeleteMutationType,
  Platform,
  RequestType,
  UserPlatformAuth,
} from './types';

export const platformAccountsApi = api.injectEndpoints({
  endpoints: builder => ({
    getPlatformAccounts: builder.query<Platform[], RequestType>({
      query: ({ platform, organization_id }) => ({
        url: `/${platform}/ads/platformAccounts`,
        method: 'GET',
        params: { organization_id },
      }),
      providesTags: (result, error, arg) => [
        {
          type: 'platformAccount',
          id: `${arg.platform}${arg.organization_id}`,
        },
      ],
      transformResponse: (response: Response<Platform[]>) => {
        return response.content.map(platform => ({
          ...platform,
          accounts: platform.accounts?.map(account => ({
            ...account,
            name: account.name || account.descriptiveName || 'noname account',
            id: Number(account.id),
          })),
        }));
      },
    }),

    deletePlatformAccount: builder.mutation<
      UserPlatformAuth,
      DeleteMutationType
    >({
      query: ({ platform, organization_id, email }) => ({
        url: `/${platform}/ads/platformAccounts`,
        method: 'DELETE',
        params: { organization_id },
        body: { email },
      }),
      invalidatesTags: (result, error, arg) => [
        {
          type: 'platformAccount',
          id: `${arg.platform}${arg.organization_id}`,
        },
        { type: 'adAccount', id: arg.platform },
      ],
      transformResponse: (response: Response<UserPlatformAuth>) =>
        response.content,
    }),
  }),
});

export const {
  useGetPlatformAccountsQuery,
  useDeletePlatformAccountMutation,
  useLazyGetPlatformAccountsQuery,
} = platformAccountsApi;
