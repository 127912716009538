import { useCallback, useRef } from 'react';

import { createEventWithPlatform, DataLayerEventName } from 'gtm';
import { EPlatforms, ROUTES } from 'platformsConstants';
import { useNavigate } from 'react-router-dom';
import { adAccountsApi } from 'redux/adAccounts/adAccountsApi';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { platformAccountsApi } from 'redux/platformAccounts/platformAccountsApi';
import { setIsLoading } from 'redux/platformAccounts/platformAccountsSlice';

type UserType = {
  user_id: string;
  organization_id: string;
  workspace_id: string;
};

export const REDIRECT_PATH = 'success';

export const useAuthPopup = () => {
  const user = useAppSelector(state => state.auth.user);
  const organization_id = useAppSelector(
    state => state.auth.user.organization_id
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const makeRedirectURL = (user: UserType, platform: EPlatforms) => {
    const { workspace_id, organization_id, user_id } = user;
    return `${
      import.meta.env.VITE_BASE_URL
    }${platform}/authenticate?workspace_id=${workspace_id}&organization_id=${organization_id}&user_id=${user_id}&redirect_url=${
      window.location.origin
    }/${REDIRECT_PATH}`;
  };

  const isStatusTrueRef = useRef(false);
  const emailRef = useRef('');

  const getParams = useCallback(
    (event: { origin: string; data: { status: string; email: string } }) => {
      if (
        event.origin === window.location.origin &&
        typeof event.data === 'object' &&
        'status' in event.data &&
        'email' in event.data
      ) {
        isStatusTrueRef.current = event.data.status === 'true';
        emailRef.current = event.data.email;
      }
    },
    []
  );

  const makePopup = useCallback(
    (platform: EPlatforms, shouldRedirect?: boolean) => {
      createEventWithPlatform(DataLayerEventName.AUTH_POPUP_LAUNCHED, platform);

      const popup = window.open(
        makeRedirectURL(user, platform),
        '_blank',
        'width=500,height=600,left=750,top=200,popup=true'
      );

      window.addEventListener('message', getParams);

      const int = setInterval(() => {
        if (popup?.closed) {
          window.removeEventListener('message', getParams);
          clearInterval(int);
          if (isStatusTrueRef.current) {
            createEventWithPlatform(DataLayerEventName.AUTH_SUCCESS, platform);

            dispatch(setIsLoading(true));

            shouldRedirect &&
              navigate(`/${ROUTES.HOME}/choose-account/`, {
                state: { platform, email: emailRef.current },
              });

            dispatch(
              platformAccountsApi.endpoints.getPlatformAccounts.initiate(
                { platform, organization_id },
                { subscribe: false, forceRefetch: true }
              )
            );
            dispatch(
              adAccountsApi.endpoints.getAdAccounts.initiate(
                { platform, organization_id },
                { subscribe: false, forceRefetch: true }
              )
            );
          }
        }
      }, 1000);
    },
    [user, getParams, dispatch, navigate, organization_id]
  );

  return { makePopup };
};
