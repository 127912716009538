import { EPlatforms } from 'platformsConstants';
import { LoginType } from 'redux/auth/types';

export enum DataLayerEventName {
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
  UPDATE_ORG = 'update_org_details',
  UPLOAD_LOGO = 'upload_org_logo',
  UPDATE_PROFILE = 'update_profile_details',
  UPLOAD_PICTURE = 'upload_profile_picture',
  PASSWORD_RESET = 'pw_reset_link_sent',
  AUTH_POPUP_LAUNCHED = 'auth_prompt_launched',
  AUTH_SUCCESS = 'auth_success',
  ADD_AD_ACCOUNT = 'add_ad_account',
  ENABLE_AUTO_TAGGING = 'enable_auto_tagging',
  DISABLE_AUTO_TAGGING = 'disable_auto_tagging',
  REMOVE_AD_ACCOUNT = 'remove_ad_account',
  ASSIGNED_TEMPLATE = 'tracking_template_assigned',
  RUN_JOB = 'run_auto_tagging_now',
  CREATE_TEMPLATE = 'new_tracking_template_start',
  SAVE_TEMPLATE = 'tracking_template_saved',
  INVITE_USER = 'new_user_invited',
  DELETE_TEMPLATE = 'tracking_template_deleted',
  DELETE_AUTH = 'auth_deleted',
  ERROR = 'error',
}

export enum MethodType {
  EMAIL = 'Email',
  GOOGLE = 'Google',
  MS = 'Microsoft',
}

export interface DataLayerObject {
  event: DataLayerEventName;
  platform?: EPlatforms;
  user_id?: string;
  method?: MethodType;
  error?: any;
  stack?: string;
}

export const pushToDataLayer = (obj: DataLayerObject) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
};

export const createSimpleEvent = (event: DataLayerEventName) => {
  pushToDataLayer({ event });
};

export const createCustomEvent = (obj: DataLayerObject) => {
  pushToDataLayer(obj);
};

export const createEventWithPlatform = (
  event: DataLayerEventName,
  platform: EPlatforms,
  counter = 1
) => {
  for (let i = 1; i <= counter; i++) {
    pushToDataLayer({ event, platform });
  }
};

export const getMethod = (type: LoginType) => {
  switch (type) {
    case LoginType.BASIC:
      return MethodType.EMAIL;
    case LoginType.GOOGLE:
      return MethodType.GOOGLE;
    case LoginType.MS:
      return MethodType.MS;
  }
};
