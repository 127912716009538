import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { platformAccountsApi } from './platformAccountsApi';
import { AccountsState } from './types';

const initialState: AccountsState = {
  isLoading: false,
  confirmedNavigation: false,
};

export const platformAccountsSlice = createSlice({
  name: 'platformAccounts',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    shouldNavigate(state, action: PayloadAction<boolean>) {
      state.confirmedNavigation = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        platformAccountsApi.endpoints.getPlatformAccounts.matchFulfilled,
        state => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        platformAccountsApi.endpoints.getPlatformAccounts.matchRejected,
        state => {
          state.isLoading = false;
        }
      );
  },
});

export const { setIsLoading, shouldNavigate } = platformAccountsSlice.actions;
export const platformAccountsReducer = platformAccountsSlice.reducer;
