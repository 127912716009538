import i18n from 'i18next';
// import Backend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';

import translation from './en/translation.json';

i18n
  // .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    returnNull: false,
    returnObjects: false,
    defaultNS: 'translation',
    react: { useSuspense: true },
    resources: {
      en: {
        translation,
      },
    },
    // backend: {
    //   projectId: '0c1814b5-4445-4e93-81c9-ad7ddb4457b0',
    //   apiKey: '732138f2-b54a-434f-bd4c-cac2a3903635',
    //   referenceLng: '[en]',
    // },
  });

export default i18n;
