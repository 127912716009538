import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

export const StyledMuiAlert = styled(MuiAlert)(({ theme }) => ({
  width: 600,
  height: 40,
  borderRadius: '8px',
  padding: '3px 50px 3px 20px',
  alignItems: 'center',
  color: theme.palette.background.paper,
  fontWeight: 400,
  '&.MuiAlert-filledError': {
    backgroundColor: theme.palette.error.main,
  },
  '&.MuiAlert-filledSuccess': {
    backgroundColor: theme.palette.success.main,
  },
  '&.MuiAlert-filledWarning': {
    backgroundColor: theme.palette.warning.main,
  },
  '&.MuiAlert-filledInfo': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiAlert-icon': {
    padding: 0,
    marginRight: '13px',
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  '& .MuiAlert-message': {
    padding: 0,
  },
  '& .MuiAlert-action': {
    padding: 0,
    margin: '0 0 0 10px',
    flexShrink: 0,
    '& button': {
      color: theme.palette.background.paper,
      textDecorationColor: theme.palette.background.paper,
    },
  },
}));
