import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SingleErrorType = {
  errorStatus?: string;
  errorMessage?: string;
  isShown?: boolean;
};

const initialState: SingleErrorType = {
  errorStatus: '',
  errorMessage: '',
  isShown: false,
};

export const singleError = createSlice({
  name: 'singleError',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<SingleErrorType>) {
      state.errorStatus = action.payload.errorStatus;
      state.errorMessage = action.payload.errorMessage;
    },
    setIsShown(state, action: PayloadAction<boolean>) {
      state.isShown = action.payload;
    },
  },
});

export const { setError, setIsShown } = singleError.actions;

export const singleErrorReducer = singleError.reducer;
