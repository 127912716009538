import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import triangle from 'assets/svg/PolygonColored.svg';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      transform: 'translateY(-4px) scaleY(1.7) rotate(45deg)',
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '7px 12px',
  },
}));

export const ArrowedTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  )
)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      content: `url(${triangle})`,
      display: 'block',
      position: 'absolute',
      top: 0,
      left: -6,
      transform: 'rotate(90deg)',
      zIndex: 0,
      backgroundColor: '#fff',
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '7px 12px',
    maxWidth: 250,
  },
}));

export const NoMaxWidthTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

export const StyledLimitationTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  )
)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      transform: 'scaleX(0.6) rotate(45deg)',
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '7px 12px',
  },
}));

export const makeLimitationTooltipStyles = (when: boolean) =>
  when
    ? {
        tooltip: { sx: { backgroundColor: '#FF8310' } },
        arrow: { sx: { color: '#FF8310' } },
      }
    : {};

export const SplitButtonTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      arrow
      classes={{ popper: className }}
      placement="top-start"
    />
  )
)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      transform: 'translateY(-5px) scaleY(2) rotate(45deg)',
      borderRadius: '0 0 3px 0',
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '9px 12px',
  },
}));

export const StyledGrayTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      arrow
      placement="top-start"
      classes={{ popper: className }}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      transform: 'translateY(-6px) scaleY(2) rotate(45deg)',
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '9px 14px',
    backgroundColor: theme.palette.secondary.dark,
  },
}));

export const AddTermLimitTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      arrow
      placement="top-start"
      classes={{ popper: className }}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      transform: 'translateY(-6px) scaleY(2) rotate(45deg) translateX(0px)',
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '9px 14px',
    backgroundColor: theme.palette.secondary.dark,
    maxWidth: 285,
  },
}));

export const StyledLastRunTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  )
)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&::before': {
      transform: 'translateY(-4px) scaleY(1.7) rotate(45deg)',
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '8px 10px',
    maxWidth: 'none',
    '& p': {
      fontWeight: 400,
    },
    '& span': {
      fontWeight: 700,
    },
  },
}));
