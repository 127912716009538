import { Dispatch, FC, SetStateAction } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { LinkedinStatuses } from 'components/HistoryTable/constants';
import { EPlatforms } from 'platformsConstants';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MetaSummary, UPDATE_STATUS } from 'redux/runHistory/types';

type StatusFilterBarProps = {
  statuses: UPDATE_STATUS[];
  setStatuses: Dispatch<SetStateAction<UPDATE_STATUS[]>>;
  summary: MetaSummary;
};

export const StatusFilterBar: FC<StatusFilterBarProps> = ({
  statuses,
  setStatuses,
  summary,
}) => {
  const { t } = useTranslation();
  const { platform } = useParams<{ platform: EPlatforms }>();

  const onStatusFilterClick = (status: UPDATE_STATUS | 'total') => {
    if (status === 'total') {
      return statuses.length > 0 && setStatuses([]);
    }

    if (statuses.includes(status)) {
      return;
    } else {
      setStatuses([status]);
    }
  };

  const isStatusActive = (status: UPDATE_STATUS | 'total') => {
    if (status === 'total') {
      return statuses.length === 0;
    }

    return statuses.includes(status);
  };

  const filteredStatuses = LinkedinStatuses.filter(({ status }) =>
    platform === EPlatforms.GOOGLE
      ? status !== UPDATE_STATUS.PARTIALLY_UPDATED
      : status
  );

  return (
    <Box display="flex" gap="10px" mt="14px">
      {filteredStatuses.map(({ status, label }) => (
        <Paper
          key={status}
          onClick={() => onStatusFilterClick(status)}
          sx={{
            width: 150,
            height: 60,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50px',
            cursor: 'pointer',
            backgroundColor: isStatusActive(status) ? 'primary.main' : 'white',
            color: isStatusActive(status) ? 'white' : 'text.primary',
          }}
        >
          <Typography mb="4px">{t(label)}</Typography>
          <Typography variant="h3">
            {summary?.[status as keyof MetaSummary] || 0}
          </Typography>
        </Paper>
      ))}
    </Box>
  );
};
