import GoogleLogo from 'assets/svg/googleLogo.svg';
import LogoLinkedin from 'assets/svg/LInLogo.svg';
import MetaLogo from 'assets/svg/metaLogo.svg';
import TiktokLogo from 'assets/svg/tiktokLogo.svg';
import { GooglePopupButton } from 'components/GooglePopupButton/GooglePopupButton';
import { LinkedInButton } from 'components/LinkedInButton/LinkedInButton';

export type RouterParams = {
  platform: EPlatforms;
  id: string;
};

export type RulesRouteParams = {
  id: string;
};

export enum ROUTES {
  HOME = 'ad-accounts',
}

export enum EPlatforms {
  GOOGLE = 'google',
  LINKEDIN = 'linkedin',
}

export enum ComingSoonPlatforms {
  META = 'meta',
  TIKTOK = 'tiktok',
}
// Split button and Accordion Summary

export const ICONS = {
  [EPlatforms.GOOGLE]: GoogleLogo,
  [EPlatforms.LINKEDIN]: LogoLinkedin,
  [ComingSoonPlatforms.META]: MetaLogo,
  [ComingSoonPlatforms.TIKTOK]: TiktokLogo,
};

export const TITLE = {
  [EPlatforms.GOOGLE]: 'Google Ads',
  [EPlatforms.LINKEDIN]: 'LinkedIn Ads',
  [ComingSoonPlatforms.META]: 'Meta Ads',
  [ComingSoonPlatforms.TIKTOK]: 'TikTok Ads',
};

//Choose account

export const TEXT = {
  [EPlatforms.GOOGLE]: 'Google',
  [EPlatforms.LINKEDIN]: 'LinkedIn',
};

export const POPUP_BUTTON = {
  [EPlatforms.GOOGLE]: GooglePopupButton,
  [EPlatforms.LINKEDIN]: LinkedInButton,
};

const params = new URLSearchParams(window.location.search);

export const PLATFORMS = Object.values(EPlatforms).filter(
  platform =>
    platform !== import.meta.env.VITE_PLATFORM_TO_DISABLE ||
    params.get(platform) === 'true'
);

export const COMING_SOON_PLATFORMS = Object.values(ComingSoonPlatforms);
