import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import isEqual from 'lodash/isEqual';
import { RulesRouteParams } from 'platformsConstants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import {
  useGetAllBrandsQuery,
  useGetOneBrandAIRulesQuery,
  useGetOneBrandQuery,
  usePostAIRulesMutation,
  usePostBrandMutation,
  useSuggestBrandAIRulesMutation,
  useSuggestBrandElementsMutation,
  useUpdateAIRulesMutation,
  useUpdateBrandMutation,
} from 'redux/searchTerms/searchTermsApi';
import { SearchTerm } from 'redux/searchTerms/types';
import * as Yup from 'yup';

import { CompetitorsError, NEW_BRIEF_PATH, ProductsError } from './constants';

type OverridableFields = {
  brandDescription: boolean;
  competitors: boolean;
  products: boolean;
  rules: boolean;
  searchTerms: boolean;
};

type ValidationErrorType = {
  briefName: string;
  brandName: string;
  brandWebsite: string;
  brandDescription: string;
  competitors: string;
  products: string;
  extraExamples: string;
};

type BrandContextType = {
  showRegeneratePopup: boolean;
  setShowRegeneratePopup: Dispatch<SetStateAction<boolean>>;
  overridableFields: OverridableFields;
  setOverridableFields: Dispatch<SetStateAction<OverridableFields>>;
  competitors: string[];
  brandDescription: string;
  products: Array<{ name: string; description: string }>;
  rules: string[];
  searchTerms: SearchTerm[];
  setSearchTerms: Dispatch<SetStateAction<SearchTerm[]>>;
  generateRules: () => void;
  setCompetitors: Dispatch<SetStateAction<string[]>>;
  setProducts: Dispatch<
    SetStateAction<Array<{ name: string; description: string }>>
  >;
  setRules: Dispatch<SetStateAction<string[]>>;
  briefName: string;
  isMagicButtonLoading: boolean;
  brandWebsite: string;
  magicButtonClick: () => void;
  handleSaveClick: () => Promise<void>;
  brandName: string;
  isSaveLoading: boolean;
  isSaveDisabled: boolean;
  error: ValidationErrorType;
  setError: Dispatch<SetStateAction<ValidationErrorType>>;
  onBriefNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBrandWebsiteChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBrandNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBrandDescriptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isBriefChanged: boolean;
  getCompetitorsError: (competitors: string[]) => string;
};

const EditBriefContext = createContext<BrandContextType | undefined>(undefined);

export const BrandProvider: FC<PropsWithChildren> = ({ children }) => {
  const { id } = useParams<keyof RulesRouteParams>() as RulesRouteParams;
  const { state } = useLocation();
  const navigate = useNavigate();

  const organization_id = useAppSelector(
    state => state.auth.user.organization_id
  );

  // state for model
  const [briefName, setBriefName] = useState<string>('');
  const [brandWebsite, setBrandWebsite] = useState<string>('');
  const [brandName, setBrandName] = useState<string>('');
  const [competitors, setCompetitors] = useState<string[]>([]);
  const [brandDescription, setBrandDescription] = useState<string>('');
  const [products, setProducts] = useState<
    Array<{ name: string; description: string }>
  >([]);
  const [rules, setRules] = useState<string[]>([]);
  const [searchTerms, setSearchTerms] = useState<Array<SearchTerm>>([]);

  const [generateElements, { isLoading: isElementsGenerating }] =
    useSuggestBrandElementsMutation();
  const [generateAIRules, { isLoading: isRulesGenerating }] =
    useSuggestBrandAIRulesMutation();

  const [postBrandElements, { isLoading: isBrandPosting }] =
    usePostBrandMutation();
  const [updateBrandMutation, { isLoading: isBrandUpdating }] =
    useUpdateBrandMutation();
  const { data } = useGetOneBrandQuery({ id }, { skip: id === NEW_BRIEF_PATH });

  const [postAIRules, { isLoading: isRulesPosting }] = usePostAIRulesMutation();
  const [updateAIRules, { isLoading: isRulesUpdating }] =
    useUpdateAIRulesMutation();
  const { data: rulesData } = useGetOneBrandAIRulesQuery(
    { brandId: id },
    { skip: id === NEW_BRIEF_PATH }
  );

  const [showRegeneratePopup, setShowRegeneratePopup] = useState(false);

  const [overridableFields, setOverridableFields] = useState<OverridableFields>(
    {
      brandDescription: true,
      competitors: true,
      products: true,
      rules: true,
      searchTerms: true,
    }
  );

  const [error, setError] = useState<ValidationErrorType>({
    briefName: '',
    brandName: '',
    brandWebsite: '',
    brandDescription: '',
    competitors: '',
    products: '',
    extraExamples: '',
  });

  const { data: brands } = useGetAllBrandsQuery({ organization_id });

  const existingBriefNames =
    brands?.reduce((acc, brand) => {
      if (brand.id !== id) {
        acc.push(brand.briefName);
      }
      return acc;
    }, [] as Array<string>) || [];

  const onBriefNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBriefName(e.target.value);
    const schema = Yup.string()
      .required('AI brief name is required')
      .max(50, 'AI brief name must be within 50 characters')
      .test('unique', 'AI brief name must be unique', value => {
        return !existingBriefNames.includes(value);
      });

    try {
      schema.validateSync(e.target.value);
      setError(prev => ({
        ...prev,
        briefName: '',
      }));
    } catch (error) {
      const e = error as Yup.ValidationError;
      setError(prev => ({
        ...prev,
        briefName: e.message,
      }));
    }
  };

  const onBrandWebsiteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBrandWebsite(e.target.value);
    const schema = Yup.string()
      .required('Website URL is required')
      .url('Website URL is not valid');

    try {
      schema.validateSync(e.target.value);
      setError(prev => ({
        ...prev,
        brandWebsite: '',
      }));
    } catch (error) {
      const e = error as Yup.ValidationError;
      setError(prev => ({
        ...prev,
        brandWebsite: e.message,
      }));
    }
  };

  const onBrandNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBrandName(e.target.value);

    const schema = Yup.string()
      .required('Company name is required')
      .min(2, 'Company name should be at least 2 characters long');

    try {
      schema.validateSync(e.target.value);
      setError(prev => ({
        ...prev,
        brandName: '',
      }));
    } catch (error) {
      const e = error as Yup.ValidationError;
      setError(prev => ({
        ...prev,
        brandName: e.message,
      }));
    }
  };

  const onBrandDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBrandDescription(e.target.value);
    const schema = Yup.string()
      .required('Company overview is required')
      .min(50, 'Company overview must be at least 50 characters long')
      .max(1000, 'Company overview must be within 1000 characters');
    try {
      schema.validateSync(e.target.value);
      setError(prev => ({
        ...prev,
        brandDescription: '',
      }));
    } catch (error) {
      const e = error as Yup.ValidationError;
      setError(prev => ({
        ...prev,
        brandDescription: e.message,
      }));
    }
  };

  const getCompetitorsError = (competitors: Array<string>) => {
    if (!competitors.length) {
      return CompetitorsError.REQUIRED;
    }

    if (competitors.length < 5) {
      return CompetitorsError.NOT_ENOUGH;
    }

    if (competitors.length > 15) {
      return CompetitorsError.TOO_MANY;
    }

    return '';
  };

  const generateRules = async () => {
    try {
      const response = await generateElements({
        brand_name: brandName,
        brand_website: brandWebsite,
        organization_id,
      }).unwrap();

      if (!response.brand_website) {
        throw new Error('Website URL is not reachable');
      }

      if (response) {
        setBrandName(response.brand_name);
        setBrandWebsite(response.brand_website);
        overridableFields.competitors &&
          setCompetitors(response.brand_competitors_list);
        overridableFields.brandDescription &&
          setBrandDescription(response.brand_short_description);
        overridableFields.products && setProducts(response.products);

        setError(prev => ({
          ...prev,
          brandName: '',
          brandWebsite: '',
          competitors: '',
          brandDescription: '',
          products: '',
        }));
      }

      if (!overridableFields.rules && !overridableFields.searchTerms) {
        return;
      }

      const rulesResponse = await generateAIRules({
        ...response,
        organization_id,
      }).unwrap();

      if (rulesResponse) {
        overridableFields.rules && setRules(rulesResponse.general_rules);
        overridableFields.searchTerms &&
          setSearchTerms(rulesResponse.search_terms);
      }
    } catch (error) {
      console.error(error);
      setError(prev => ({
        ...prev,
        brandWebsite:
          'Website URL is not reachable. Please check the URL and try again',
      }));
    }
  };

  const magicButtonClick = () => {
    const isFieldsPrefilled =
      brandDescription ||
      competitors.length ||
      products.length ||
      rules.length ||
      searchTerms.length;

    if (isFieldsPrefilled) {
      setOverridableFields({
        brandDescription: !brandDescription,
        competitors: !competitors.length,
        products: !products.length,
        rules: !rules.length,
        searchTerms: !searchTerms.length,
      });
      setShowRegeneratePopup(true);
      return;
    }

    generateRules();
  };

  const handleSaveClick = async () => {
    if (
      !briefName ||
      !brandWebsite ||
      !brandName ||
      !brandDescription ||
      !competitors.length ||
      !products.length
    ) {
      setError(prev => ({
        ...prev,
        briefName: !briefName ? 'AI brief name is required' : '',
        brandWebsite: !brandWebsite ? 'Website URL is required' : '',
        brandName: !brandName ? 'Company name is required' : '',
        brandDescription: !brandDescription
          ? 'Company overview is required'
          : '',
        competitors: getCompetitorsError(competitors),
        products: !products.length ? ProductsError.REQUIRED : '',
      }));
      throw new Error('Required fields are missing');
    }

    try {
      if (id === NEW_BRIEF_PATH) {
        const response = await postBrandElements({
          organizationId: organization_id,
          adAccounts: state?.adAccountsToAdd || [],
          briefName: briefName,
          name: brandName,
          website: brandWebsite,
          shortDescription: brandDescription,
          competitorsList: competitors,
          products: products,
        }).unwrap();

        await postAIRules({
          organizationId: organization_id,
          brandId: response.id,
          rules,
          searchTermsExcludeExamples: searchTerms,
        }).unwrap();

        navigate(`/ai-briefs/edit-brief/${response.id}`, {
          state: { from: state?.from },
        });
      } else {
        updateBrandMutation({
          id: id,
          organizationId: organization_id,
          adAccounts: data?.adAccounts || [],
          briefName: briefName,
          name: brandName,
          website: brandWebsite,
          shortDescription: brandDescription,
          competitorsList: competitors,
          products: products,
        });

        updateAIRules({
          organizationId: organization_id,
          brandId: id,
          rules,
          searchTermsExcludeExamples: searchTerms,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data) {
      setBriefName(data.briefName);
      setBrandName(data.name);
      setBrandWebsite(data.website);
      setBrandDescription(data.shortDescription);
      setCompetitors(data.competitorsList);
      setProducts(data.products);
    }
  }, [data]);

  useEffect(() => {
    if (rulesData) {
      setRules(rulesData.rules);
      setSearchTerms(rulesData.searchTermsExcludeExamples);
    }
  }, [rulesData]);

  const isMagicButtonLoading = isElementsGenerating || isRulesGenerating;
  const isSaveLoading =
    isBrandPosting || isBrandUpdating || isRulesPosting || isRulesUpdating;

  const isBriefChanged =
    briefName !== data?.briefName ||
    brandWebsite !== data?.website ||
    brandName !== data?.name ||
    brandDescription !== data?.shortDescription ||
    !isEqual(competitors, data?.competitorsList) ||
    !isEqual(products, data?.products) ||
    !isEqual(rules, rulesData?.rules) ||
    !isEqual(searchTerms, rulesData?.searchTermsExcludeExamples);

  const isSaveDisabled =
    searchTerms.length > 50 ||
    Object.values(error).some(Boolean) ||
    !isBriefChanged;

  const value = {
    showRegeneratePopup,
    setShowRegeneratePopup,
    overridableFields,
    setOverridableFields,
    competitors,
    brandDescription,
    products,
    rules,
    searchTerms,
    generateRules,
    setCompetitors,
    setProducts,
    setRules,
    setSearchTerms,
    briefName,
    isMagicButtonLoading,
    brandWebsite,
    magicButtonClick,
    handleSaveClick,
    brandName,
    isSaveLoading,
    isSaveDisabled,
    error,
    setError,
    onBriefNameChange,
    onBrandWebsiteChange,
    onBrandNameChange,
    onBrandDescriptionChange,
    isBriefChanged,
    getCompetitorsError,
  };

  return (
    <EditBriefContext.Provider value={value}>
      {children}
    </EditBriefContext.Provider>
  );
};

export const useBrandContext = () => {
  const context = useContext(EditBriefContext);

  if (!context) {
    throw new Error(
      'useEditBriefContext must be used within a EditBriefProvider'
    );
  }

  return context;
};
