import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { billingApi } from './billingApi';
import { StateModel, SubscriptionStatuses, SubscriptionType } from './types';

const initialState: StateModel = {
  isShown: false,
  shouldBlock: false,
  isMaxConnected: false,
  bannerMessage: '',
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    resetSubscriptionLimitations: () => initialState,
    setIsShown(state, action: PayloadAction<boolean>) {
      state.isShown = action.payload;
    },
    setMessage(state, action: PayloadAction<string>) {
      state.bannerMessage = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addMatcher(
      billingApi.endpoints.getSubscription.matchRejected,
      state => {
        state.isShown = false;
      }
    );

    builder.addMatcher(
      billingApi.endpoints.getSubscription.matchFulfilled,
      (state, { payload }) => {
        if (!payload) {
          state.isShown = false;
        }

        const {
          status,
          type,
          alreadyConnectedAdAccountsCount,
          max_allowed_ac,
          extra_allowed_ac,
        } = payload;

        state.shouldBlock =
          type === SubscriptionType.BLOCKED ||
          type === SubscriptionType.PAUSED ||
          type === SubscriptionType.DELETED;

        if (type === SubscriptionType.PAYED) {
          state.isMaxConnected =
            alreadyConnectedAdAccountsCount >=
            (max_allowed_ac || 0) + (extra_allowed_ac || 0);
        } else {
          state.isMaxConnected = false;
        }

        if (
          status === SubscriptionStatuses.ACTIVE &&
          type === SubscriptionType.PAYED
        ) {
          state.isShown =
            alreadyConnectedAdAccountsCount >=
            (max_allowed_ac || 0) + (extra_allowed_ac || 0);
        } else state.isShown = true;
      }
    );
  },
});

export const { setIsShown, resetSubscriptionLimitations, setMessage } =
  subscriptionSlice.actions;
export const subscriptionReducer = subscriptionSlice.reducer;
