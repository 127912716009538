import { useMemo } from 'react';

import {
  BoostedAdAccountType,
  PlatformAccountConnectionStatus,
} from 'redux/adAccounts/types';

import { Order, useTableSorting } from './useTableSorting';

export enum SortableKeys {
  RESOURCE_NAME = 'resourceName',
  CONNECTED_AT = 'connectedAt',
  LAST_RUN = 'lastRun',
  NEXT_RUN = 'nextRun',
  NEXT_RUN_SEARCH_TERM = 'nextRunSearchTerm',
  LAST_RUN_SEARCH_TERM = 'lastRunSearchTerm',
  PERIOD_SUMMARY = 'periodSummary',
}

export type SortableData = Pick<
  BoostedAdAccountType,
  | 'resourceName'
  | 'connectedAt'
  | 'lastRun'
  | 'nextRun'
  | 'connectionStatus'
  | 'enabled'
  | 'lastRunSearchTerm'
  | 'periodSummary'
  | 'isSearchTermsValidationEnabled'
>;

function descendingStringComparator(a: SortableData, b: SortableData) {
  if (b[SortableKeys.RESOURCE_NAME] < a[SortableKeys.RESOURCE_NAME]) {
    return 1;
  }
  if (b[SortableKeys.RESOURCE_NAME] > a[SortableKeys.RESOURCE_NAME]) {
    return -1;
  }
  return 0;
}

function descendingDateComparator(a: SortableData, b: SortableData) {
  const dateA = Date.parse(a[SortableKeys.CONNECTED_AT]);
  const dateB = Date.parse(b[SortableKeys.CONNECTED_AT]);

  if (dateA === dateB) {
    return descendingStringComparator(a, b);
  }

  if (dateB < dateA) {
    return -1;
  }
  if (dateB > dateA) {
    return 1;
  }
  return 0;
}

function descendingLastRunComparator(a: SortableData, b: SortableData) {
  const currentDate = new Date();

  const dateA = a[SortableKeys.LAST_RUN]?.createdAt
    ? new Date(a[SortableKeys.LAST_RUN].createdAt)
    : currentDate;

  const dateB = b[SortableKeys.LAST_RUN]?.createdAt
    ? new Date(b[SortableKeys.LAST_RUN].createdAt)
    : currentDate;

  if (dateA === dateB) {
    return descendingStringComparator(a, b);
  }

  if (dateB < dateA) {
    return -1;
  }
  if (dateB > dateA) {
    return 1;
  }
  return 0;
}

function descendingLastRunSearchTermComparator(
  a: SortableData,
  b: SortableData
) {
  const currentDate = new Date();

  const dateA = a[SortableKeys.LAST_RUN_SEARCH_TERM]?.createdAt
    ? new Date(a[SortableKeys.LAST_RUN_SEARCH_TERM].createdAt)
    : currentDate;

  const dateB = b[SortableKeys.LAST_RUN_SEARCH_TERM]?.createdAt
    ? new Date(b[SortableKeys.LAST_RUN_SEARCH_TERM].createdAt)
    : currentDate;

  if (dateA === dateB) {
    return descendingStringComparator(a, b);
  }

  if (dateB < dateA) {
    return -1;
  }
  if (dateB > dateA) {
    return 1;
  }
  return 0;
}

function descendingPeriodSummaryComparator(a: SortableData, b: SortableData) {
  const costA = a[SortableKeys.PERIOD_SUMMARY]?.calculatedExcludedCost || 0;
  const costB = b[SortableKeys.PERIOD_SUMMARY]?.calculatedExcludedCost || 0;

  if (costA === costB) {
    return descendingStringComparator(a, b);
  }

  if (costB < costA) {
    return -1;
  }

  if (costB > costA) {
    return 1;
  }

  return 0;
}

function descendingNextRunComparator(a: SortableData, b: SortableData) {
  const currentDate = new Date();

  if (
    a.connectionStatus !== PlatformAccountConnectionStatus.CONNECTED &&
    b.connectionStatus === PlatformAccountConnectionStatus.CONNECTED
  ) {
    return -1;
  }
  if (
    a.connectionStatus === PlatformAccountConnectionStatus.CONNECTED &&
    b.connectionStatus !== PlatformAccountConnectionStatus.CONNECTED
  ) {
    return 1;
  }

  if (!a.enabled && b.enabled) {
    return -1;
  }
  if (a.enabled && !b.enabled) {
    return 1;
  }

  if (!a.lastRun && b.lastRun) {
    return -1;
  }
  if (a.lastRun && !b.lastRun) {
    return 1;
  }

  const dateA = a[SortableKeys.NEXT_RUN]?.nextJobTime
    ? new Date(a[SortableKeys.NEXT_RUN].nextJobTime)
    : currentDate;

  const dateB = b[SortableKeys.NEXT_RUN]?.nextJobTime
    ? new Date(b[SortableKeys.NEXT_RUN].nextJobTime)
    : currentDate;

  if (dateA === dateB) {
    return descendingStringComparator(a, b);
  }

  if (dateB < dateA) {
    return -1;
  }
  if (dateB > dateA) {
    return 1;
  }
  return 0;
}

function descendingNextRunSearchTermComparator(
  a: SortableData,
  b: SortableData
) {
  const currentDate = new Date();

  if (
    a.connectionStatus !== PlatformAccountConnectionStatus.CONNECTED &&
    b.connectionStatus === PlatformAccountConnectionStatus.CONNECTED
  ) {
    return -1;
  }
  if (
    a.connectionStatus === PlatformAccountConnectionStatus.CONNECTED &&
    b.connectionStatus !== PlatformAccountConnectionStatus.CONNECTED
  ) {
    return 1;
  }

  if (!a.isSearchTermsValidationEnabled && b.isSearchTermsValidationEnabled) {
    return -1;
  }
  if (a.isSearchTermsValidationEnabled && !b.isSearchTermsValidationEnabled) {
    return 1;
  }

  if (!a.lastRunSearchTerm && b.lastRunSearchTerm) {
    return -1;
  }
  if (a.lastRunSearchTerm && !b.lastRunSearchTerm) {
    return 1;
  }

  const dateA = a[SortableKeys.NEXT_RUN]?.nextJobTime
    ? new Date(a[SortableKeys.NEXT_RUN].nextJobTime)
    : currentDate;

  const dateB = b[SortableKeys.NEXT_RUN]?.nextJobTime
    ? new Date(b[SortableKeys.NEXT_RUN].nextJobTime)
    : currentDate;

  if (dateA === dateB) {
    return descendingStringComparator(a, b);
  }

  if (dateB < dateA) {
    return -1;
  }
  if (dateB > dateA) {
    return 1;
  }
  return 0;
}

function getComparator(
  order: Order,
  orderBy: SortableKeys
): (a: SortableData, b: SortableData) => number {
  switch (orderBy) {
    case SortableKeys.RESOURCE_NAME:
      return order === Order.DESC
        ? (a, b) => descendingStringComparator(a, b)
        : (a, b) => -descendingStringComparator(a, b);
    case SortableKeys.CONNECTED_AT:
      return order === Order.DESC
        ? (a, b) => descendingDateComparator(a, b)
        : (a, b) => -descendingDateComparator(a, b);
    case SortableKeys.LAST_RUN:
      return order === Order.DESC
        ? (a, b) => descendingLastRunComparator(a, b)
        : (a, b) => -descendingLastRunComparator(a, b);
    case SortableKeys.LAST_RUN_SEARCH_TERM:
      return order === Order.DESC
        ? (a, b) => descendingLastRunSearchTermComparator(a, b)
        : (a, b) => -descendingLastRunSearchTermComparator(a, b);
    case SortableKeys.NEXT_RUN:
      return order === Order.DESC
        ? (a, b) => descendingNextRunComparator(a, b)
        : (a, b) => -descendingNextRunComparator(a, b);
    case SortableKeys.NEXT_RUN_SEARCH_TERM:
      return order === Order.DESC
        ? (a, b) => descendingNextRunSearchTermComparator(a, b)
        : (a, b) => -descendingNextRunSearchTermComparator(a, b);
    case SortableKeys.PERIOD_SUMMARY:
      return order === Order.DESC
        ? (a, b) => descendingPeriodSummaryComparator(a, b)
        : (a, b) => -descendingPeriodSummaryComparator(a, b);
    default:
      return order === Order.DESC
        ? (a, b) => descendingStringComparator(a, b)
        : (a, b) => -descendingStringComparator(a, b);
  }
}

export const useAdAccountsTableSorting = (
  initialRows: BoostedAdAccountType[]
) => {
  const { order, orderBy, handleRequestSort } = useTableSorting<SortableKeys>({
    initialOrderBy: SortableKeys.CONNECTED_AT,
  });

  const sortedRows = useMemo(
    () => initialRows?.slice().sort(getComparator(order, orderBy)),
    [initialRows, order, orderBy]
  );

  return { order, orderBy, handleRequestSort, sortedRows };
};
