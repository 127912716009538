import { useEffect } from 'react';

import sidebarLogoMax from 'assets/images/sidebarLogoMajor.png';
import sidebarLogoMaxSearch from 'assets/images/sidebarLogoMajor_searchTerms.png';
import {
  AutoUtmModeSortableHeadCells,
  SearchTermModeSortableHeadCells,
} from 'components/AdAccountsTable/constants';
import {
  MenuItemsAutoUTMMode,
  MenuItemsSearchTermMode,
} from 'components/SidebarMenu/constants';
import { EPlatforms, PLATFORMS } from 'platformsConstants';
import { useCookies } from 'react-cookie';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AppMode } from 'redux/appMode/types';
import { useAppSelector } from 'redux/hooks';

const APP_MODE_CONFIG = {
  [AppMode.AUTO_UTM]: {
    restrictedRoutes: ['ai-briefs', 'analysis'],
    sidebarLogoOpened: sidebarLogoMax,
    sidebarMenu: MenuItemsAutoUTMMode,
    switchButton: 'sidebar.menu.search_terms',
    platforms: PLATFORMS,
    isBulkActionAvailable: true,
    isTableCheckboxAvailable: true,
    isTrackingTemplateSelectorAvailable: true,
    isAIRulesSelectorAvailable: false,
    adAccountTableHeaders: AutoUtmModeSortableHeadCells,
    isBadImpressionsAvailable: false,
  },
  [AppMode.SEARCH_TERMS]: {
    restrictedRoutes: ['url-templates', 'run-history'],
    sidebarLogoOpened: sidebarLogoMaxSearch,
    sidebarMenu: MenuItemsSearchTermMode,
    switchButton: 'sidebar.menu.auto_utm',
    platforms: [EPlatforms.GOOGLE],
    isBulkActionAvailable: false,
    isTableCheckboxAvailable: false,
    isTrackingTemplateSelectorAvailable: false,
    isAIRulesSelectorAvailable: true,
    adAccountTableHeaders: SearchTermModeSortableHeadCells,
    isBadImpressionsAvailable: true,
  },
};

export const useAppModeConfig = () => {
  const { pathname } = useLocation();

  const [params] = useSearchParams();

  const searchTermParam = params.get('searchTerms');

  const [cookies, setCookie] = useCookies(['searchTerms']);

  useEffect(() => {
    if (searchTermParam) {
      setCookie('searchTerms', searchTermParam);
    }
  }, [searchTermParam, setCookie]);

  const enableSwitcher =
    import.meta.env.VITE_ENABLE_TOGGLER === 'true' ||
    searchTermParam === 'true' ||
    cookies.searchTerms === true;

  const appMode = useAppSelector(state => state.appMode.appMode) as AppMode;

  const config = APP_MODE_CONFIG[appMode];

  const isRouteRestricted = config.restrictedRoutes.some(route =>
    pathname.includes(route)
  );

  return { isRouteRestricted, ...config, appMode, enableSwitcher };
};
