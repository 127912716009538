import { useLocation } from 'react-router-dom';

export const TOKEN_KEY = 'tkn';

export const useGetToken = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get(TOKEN_KEY) as string;

  return token;
};
